/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import view from "mocks/ViewData.json";
import { useDispatch, useSelector } from "react-redux";
import {
  Status_on,
  addComments,
  categoryDropdown,
  countryDropdown,
  devcomplexityDropdown,
  fieldDropdown,
  meritUsers,
  priorityDropdown,
  priorityDropdownLease,
  priorityDropdownNew,
  providerDropdown,
  reportedByDropdown,
  reportedByDropdownNew,
  requestTypeDropdown,
  rerunDropdown,
  resolutionDropdown,
  statusDropdown,
  typeDropdown,
  userDropdown,
  viewAticketData,
  worktypeDropdown,
} from "redux/manageissues/action";
import { Box } from "@mui/system";
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MaterialUIButton from "components/commonComponents/button/button";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { calendar, pdfIcon, remove } from "../../../../assets/index";
import useStyles from "./manageIssues.styles";
import moment from "moment";
import EditIssuse from "./editissue";
import MaterialUIModal from "components/commonComponents/modal/modal";
import { useLocation } from "react-router-dom";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import Textbox from "components/commonComponents/textbox/textbox";
import { useFormik } from "formik";
import {
  fetchAttachments,
  fetchComments,
  fetchDevAttachments,
  fetchManageissueid,
  fetchResolutionstatus,
  fetchusers,
  getCategory,
  getCountry,
  getDevcomplexity,
  getField,
  getMake,
  getModel,
  getPriority,
  getProvider,
  getRerun,
  getStatus,
  getType,
  getWorktype,
  reportedBy,
  requestType,
  saveDevFiles,
  saveFiles,
  setMeritUser,
} from "redux/app/actions";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Required } from "utils/validation helper";
import Datepicker from "components/commonComponents/datepicker/datepicker";
import Autocompletedropdown from "components/commonComponents/autocomplete/autocompletedropdown";
import Dropdown from "components/commonComponents/dropdown/dropdown";
import { MenuItem } from "@material-ui/core";
import Radiobutton from "components/commonComponents/radiobutton/radiobutton";
import AlertDialog from "components/commonComponents/alertDialog";
import DoneIcon from "@mui/icons-material/Done";
import fileDownload from "js-file-download";
import MultipleAutocomplete from "components/commonComponents/autocomplete/multipleAutocomplete";
import { makeDependency, modelDependency } from "redux/managesources/action";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DropzoneEdit from "components/commonComponents/dragDropField/indexedit";
import DropzoneDevEdit from "components/commonComponents/dragDropField/indexdevedit";
import Datetimepicker from "components/commonComponents/datepicker/datetime";
import { getAllSla, getHolidayList } from "redux/slaandholidays/actions";
function ViewIssue(props) {
  const classes = useStyles();
  const select = useSelector((state) => state.manageIssue);
  const data = useSelector((state) => state.manageIssue.manageIssuedataid);
  const selector = JSON.parse(localStorage.getItem("roleData"));
  const [edit, setEdit] = useState([]);
  const dataattach = useSelector((state) => state.manageIssue.attachments);
  const devdataattach = useSelector(
    (state) => state.manageIssue.devAttachments
  );
  const Pname = useSelector((state) => state.project.manageissueprojectname);

  const [showEditIssue, setShowEditIssue] = useState(false);
  const [isFileCleared, setIsFileCleared] = useState(false);
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.manageIssue.comments);
  const id = useSelector((state) => state.project.manageissue);
  const [sla, setSla] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [viewedit, setViewedit] = useState(false);
  const [viewissue, SetViewIssue] = useState(true);
  const location = useLocation();
  const role = localStorage.getItem("role");
  useEffect(() => {
    callingDropdowndata();
    dispatch(
      fetchComments(
        data.developerCommentsNavigation ? data.developerCommentsNavigation : []
      )
    );
  }, []);
  const optionRendererm = (props, option, { selected }) => {
    const selectAllProps =
      option.make1 === "ALL" // To control the state of 'select-all' checkbox
        ? {
            checked:
              formik.values.make?.length === 0
                ? false
                : select.make.length === formik.values.make?.length,
          }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.make1}
      </li>
    );
  };
  const handleToggleOptionm = (selectedOptions) => {
    formik.setFieldValue("make", selectedOptions);
  };
  const validateComments = (values) => {
    const errors = {};
    if (values.developerComments1.length > 1000) {
      errors.developerComments1 = "Exceeds 1000 characters";
    }
    return errors;
  };
  const name = localStorage.getItem("firstName");
  const last = localStorage.getItem("lastName");
  const validate = (values, props) => {
    const errors = {};
    if (!Required(values.countryId)) {
      errors.countryId = true;
    }
    if (values.make?.length === 0) {
      errors.make = true;
    }
    if (Pname === "OEMS") {
      if (values.modelId.length === 0) {
        errors.modelId = true;
      }
    }
    if (!Required(values.reportedBy)) {
      errors.reportedBy = true;
    } else if (values.reportedBy.length > 50) {
      errors.reportedBy = "Exceeds 50 characters";
    }
    if (!Required(values.typeId)) {
      errors.typeId = true;
    }
    if (!Required(values.requestId)) {
      errors.requestId = true;
    }
    if (Pname === "LEASE OPERATOR") {
      if (!Required(values.provider)) {
        errors.provider = true;
      }
    }
    if (Pname === "TRANSACTIONAL ANALYSIS") {
      if (!Required(values.website)) {
        errors.website = true;
      }
    }
    if (!Required(values.priorityId)) {
      errors.priorityId = true;
    }
    /*    if (role === "MeritQC") {
      if (!Required(values.workTypeId)) {
        errors.workTypeId = true;
      }
    } */
    /*    if (role === "MeritQC") {
      if (!Required(values.statusId)) {
        errors.statusId = true;
      }
    } */
    /*     if (role === "Merit QC" || role === "Merit Admin") {
      if (!Required(values.workTypeId)) {
        errors.workTypeId = true;
      }
    } */
    if (role === "Merit QC" || role === "Merit Admin") {
      if (!Required(values.statusId)) {
        errors.statusId = true;
      }
    }
    if (!Required(values.rerun)) {
      errors.rerun = true;
    }
    const feed = values.feedback ? values.feedback.length : "";
    if (!Required(values.feedback)) {
      errors.feedback = true;
    } else if (feed > 2000) {
      errors.feedback = "Exceeds 2000 characters";
    }
    const meritjustify = values.meritJustification
      ? values.meritJustification.length
      : "";
    const devcomments = values.developerCommentsView
      ? values.developerCommentsView.length
      : "";

    if (meritjustify > 400) {
      errors.meritJustification = "Exceeds 400 characters";
    }
    if (devcomments > 400) {
      errors.developerCommentsView = "Exceeds 400 characters";
    }
    if (values.assignedTo && role == "Merit Developer") {
      if (!Required(values.complexity)) {
        errors.complexity = true;
      }
    }

    if (role === "Merit Developer") {
      if (!Required(values.assignedTo)) {
        errors.assignedTo = true;
      }
    }
    return errors;
  };
  const formikComment = useFormik({
    initialValues: {
      developerId: null,
      ticketId: data.ticketId,
      developerComments1: "",
      createdBy: name + " " + last,
      createdDate: moment().format(),
    },
    //enableReinitialize: true,

    validate: (values) => {
      const errors = {};
      if (values.developerComments1.length > 1000) {
        errors.developerComments1 = "Exceeds 1000 characters";
      }
      return errors;
    },
    onSubmit: (values) => {
      dispatch(fetchComments([...comments, formikComment.values]));
      formikComment.resetForm();
    },
  });
  const callingDropdowndata = () => {
    dispatch(countryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCountry([]));
        } else {
          dispatch(getCountry(res.data.result));
        }
      } else {
        dispatch(getCountry([]));
      }
    });

    Object.keys(data).length > 0 &&
      dispatch(makeDependency([data.countryId], id)).then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(getMake([]));
          } else {
            dispatch(getMake(res.data.result));
          }
        } else {
          dispatch(getMake([]));
        }
      });
    Object.keys(data).length > 0 &&
      dispatch(
        modelDependency(
          data.fkMakeIssueTracker?.map((e) => e.makeId),
          id
        )
      ).then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            dispatch(getModel([]));
          } else {
            dispatch(getModel(res.data.result));
          }
        } else {
          dispatch(getModel([]));
        }
      });
    dispatch(getAllSla(1, 20, "", id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            setSla([]);
          } else {
            setSla(res.data.result);
          }
        }
      }
    });
    dispatch(getHolidayList(moment().year(), id)).then((res) => {
      if (res) {
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            setHoliday([]);
          } else {
            setHoliday(res.data.result);
          }
        }
      }
    });
    dispatch(requestTypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(requestType([]));
        } else {
          dispatch(requestType(res.data.result));
        }
      } else {
        dispatch(requestType([]));
      }
    });
    // dispatch(reportedByDropdown(id)).then((res) => {
    //   if (res.data.statusCode === 200) {
    //     if (res.data.result === "No records found") {
    //       dispatch(reportedBy([]));
    //     } else {
    //       dispatch(reportedBy(res.data.result));
    //     }
    //   } else {
    //     dispatch(reportedBy([]));
    //   }
    // });
    dispatch(
      reportedByDropdownNew(
        id,
        role.toLowerCase().includes("merit") ? false : true
      )
    ).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(reportedBy([]));
        } else {
          dispatch(reportedBy(res.data.result));
        }
      } else {
        dispatch(reportedBy([]));
      }
    });
    dispatch(categoryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCategory([]));
        } else {
          dispatch(getCategory(res.data.result));
        }
      } else {
        dispatch(getCategory([]));
      }
    });

    dispatch(typeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getType([]));
        } else {
          dispatch(getType(res.data.result));
        }
      } else {
        dispatch(getType([]));
      }
    });
    dispatch(providerDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getProvider([]));
        } else {
          dispatch(getProvider(res.data.result));
        }
      } else {
        dispatch(getProvider([]));
      }
    });
    dispatch(priorityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getPriority([]));
        } else {
          dispatch(getPriority(res.data.result));
        }
      } else {
        dispatch(getPriority([]));
      }
    });
    dispatch(fieldDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getField([]));
        } else {
          dispatch(getField(res.data.result));
        }
      } else {
        dispatch(getField([]));
      }
    });
    dispatch(rerunDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getRerun([]));
        } else {
          dispatch(getRerun(res.data.result));
        }
      } else {
        dispatch(getRerun([]));
      }
    });
    dispatch(worktypeDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getWorktype([]));
        } else {
          dispatch(getWorktype(res.data.result));
        }
      } else {
        dispatch(getWorktype([]));
      }
    });
    dispatch(statusDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getStatus([]));
        } else {
          dispatch(getStatus(res.data.result));
        }
      } else {
        dispatch(getStatus([]));
      }
    });
    dispatch(categoryDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getCategory([]));
        } else {
          dispatch(getCategory(res.data.result));
        }
      } else {
        dispatch(getCategory([]));
      }
    });
    dispatch(
      Status_on(data.category ? data.category.issueCategory1 : "", id)
    ).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchResolutionstatus([]));
        } else dispatch(fetchResolutionstatus(res.data.result));
      } else {
        dispatch(fetchResolutionstatus([]));
      }
    });
    dispatch(meritUsers(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(setMeritUser([]));
        } else dispatch(setMeritUser(res.data.result));
      } else {
        dispatch(setMeritUser([]));
      }
    });
    dispatch(userDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(fetchusers([]));
        } else dispatch(fetchusers(res.data.result));
      } else {
        dispatch(fetchusers([]));
      }
    });
    dispatch(devcomplexityDropdown(id)).then((res) => {
      if (res.data.statusCode === 200) {
        if (res.data.result === "No records found") {
          dispatch(getDevcomplexity([]));
        } else dispatch(getDevcomplexity(res.data.result));
      } else {
        dispatch(getDevcomplexity([]));
      }
    });
  };
  const [isFileCleareddev, setIsFileCleareddev] = useState(false);
  const [showalert, setShowalert] = useState(false);

  const handleDelete = (event) => {
    var list = dataattach;
    var separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == event) {
        values.splice(i, 1);
        list = values.join(separator);
      }
    }
    dispatch(fetchAttachments(list));
    formik.setFieldValue("attachments", list);
  };
  const handleDeletedev = (event) => {
    var list = devdataattach;
    var separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == event) {
        values.splice(i, 1);
        list = values.join(separator);
      }
    }
    dispatch(fetchDevAttachments(list));
    formik.setFieldValue("attachments", list);
  };
  const devopenFileInNewTab = async (url) => {
    var UrlName =
      process.env.REACT_APP_MANAGE_ISSUE_BASE_URL +
      `/api/v1/manageissues/getfile?FileName=${url}`;
    window.open(UrlName);
    fileDownload(UrlName, url);
  };
  const cancel = () => {
    props.close();
    dispatch(fetchAttachments(""));
    dispatch(saveFiles([]));
    dispatch(fetchDevAttachments(""));
    dispatch(saveDevFiles([]));
    //SetViewIssue(true);
  };

  const openFileInNewTab = async (url) => {
    var UrlName =
      process.env.REACT_APP_MANAGE_ISSUE_BASE_URL +
      `/api/v1/manageissues/getfile?FileName=${url}`;
    window.open(UrlName);
    fileDownload(UrlName, url);
  };
  const truncateFileName = (text) => {
    if (text && text.length <= 20) return text;
    if (text) {
      var string,
        filename = "",
        splitArr = text && text.split("."),
        extension = "." + splitArr[splitArr.length - 1];
      for (var i = 0; i < splitArr.length - 1; i++) {
        filename += splitArr[i];
      }

      string =
        filename.substring(0, 7) +
        "..." +
        filename.substring(filename.length - 7, filename.length) +
        extension;
      return string;
    }
  };
  const precountry = data.country && {
    id: data.country.countryId,
    label: data.country.countryCode + "-" + data.country.region,
  };
  const premake = select.make && {
    id: select.make.makeId,
    label: select.make.make1,
  };

  const premodel =
    data.fkIssueTracker &&
    data.fkIssueTracker.map((e) => {
      return {
        modelId: e.model.modelId,
        model1: e.model.model1,
      };
    });
  const precategory = data.category && {
    id: data.category.categoryId,
    label: data.category.issueCategory1,
  };

  const pretype = data.type && {
    id: data.type.typeId,
    label: data.type.issueType1,
  };
  const prepriority = data.priority && {
    id: data.priority.priorityId,
    label: data.priority.issuePriority1,
  };
  const prefield = data.field && {
    id: data.field.fieldId,
    label: data.field.issueField1,
  };
  const preworktype = data.workType && {
    label: data.workType.workType1,
    id: data.workType.workTypeId,
  };
  const prestatus = data.status && {
    label: data.status.status1,
    id: data.status.statusId,
  };
  const prereportedByNavigation = data.reportedByNavigation && {
    id: data.reportedByNavigation.reportedById,
    label: data.reportedByNavigation.reportedBy1,
  };
  const preresolutionstatus = data.resolution && {
    id: data.resolution.resolutionId,
    label: data.resolution.resolutionStatus1,
  };
  const precomplexity = data.complexity && {
    id: data.complexity,
    label: data.complexity,
  };
  const preassignedTo = data.assignedTo && {
    id: data.assignedUserId,
    label: data.assignedTo,
  };

  function addDecimalBusinessDays(
    startDateTime,
    numDecimalBusinessDays,
    holidays
  ) {
    console.log(startDateTime, numDecimalBusinessDays, holidays);

    // Convert startDateTime to a moment object in UTC time
    let currentDate = moment.utc(startDateTime);

    // Function to check if a given date is a weekend (Saturday or Sunday)
    function isWeekend(date) {
      const day = date.day();
      return day === 0 || day === 6; // 0 represents Sunday, 6 represents Saturday
    }

    // Function to check if a given date is a holiday
    function isHoliday(date, holidays) {
      if (Array.isArray(holidays)) {
        for (let holiday of holidays) {
          if (date.isSame(moment.utc(holiday), "day")) {
            return true; // Date is a holiday
          }
        }
      }
      return false; // Date is not a holiday
    }

    // Convert decimal days to hours
    const totalHours = numDecimalBusinessDays * 24;
    let remainingHours = totalHours;

    // Loop through each hour and add to the current date while skipping weekends and holidays
    while (remainingHours > 0) {
      currentDate.add(1, "hour"); // Move to the next hour

      if (!isWeekend(currentDate) && !isHoliday(currentDate, holidays)) {
        remainingHours -= 1; // Deduct one hour
      }
    }

    // Return the final date adjusted to UTC time
    return currentDate.format("YYYY-MM-DD HH:mm:ss");
  }

  function getTotalDays(complexityName, priorityName) {
    console.log(complexityName, priorityName);
    const filteredData = sla.filter(
      (item) =>
        item.complexityName?.toLowerCase() === complexityName?.toLowerCase() &&
        item.priorityName?.toLowerCase() === priorityName?.toLowerCase()
    );

    const totalDays = filteredData.reduce((sum, item) => sum + item.days, 0);
    console.log(totalDays);
    return totalDays;
  }

  const setResStatus = () => {
    if (
      data.resolution.resolutionStatus1.toLowerCase() === "completed" ||
      data.resolution.resolutionStatus1.toLowerCase() === "dispute" ||
      data.resolution.resolutionStatus1.toLowerCase() === "fixed"
    )
      return moment().utc().add(0, "days");
  };
  const setAssignedto = () => {
    return data.assignedTo
      ? data.assignedDate
        ? data.assignedDate
        : moment().add(0, "days")
      : "";
  };
  const formData = new FormData();
  const formik = useFormik({
    initialValues: {
      ticketId: data.ticketId,
      dateRaised: data.dateRaised,
      dateofFile: data.dateofFile,
      countryId: data.country ? precountry : null,
      country: data.country
        ? data.country.countryCode + " - " + data.country.region
        : "",
      provider: data.provider
        ? data.provider && {
            id: data.provider.providerId,
            label: data.provider.leasingProviderName,
          }
        : "",
      website: data.website
        ? data.website && {
            id: data.website.websiteId,
            label: data.website.website1,
          }
        : "",
      make: data.fkMakeIssueTracker
        ? data.fkMakeIssueTracker &&
          data.fkMakeIssueTracker.map((e) => {
            return {
              makeId: e.make?.makeId,
              make1: e.make?.make1,
            };
          })
        : [],
      modelId: data.fkIssueTracker ? premodel : [],
      fieldId: data.field ? prefield : null,
      categoryId: data.category ? precategory : null,
      typeId: data.type ? pretype : null,
      priorityId: data.priority ? prepriority : null,
      feedback: data.feedback,
      attachments: data.attachments ? data.attachments : null,
      files: [],
      devAttachments: data.devAttachments ? data.devAttachments : null,
      devfiles: [],
      rerunId: data.rerunId,
      meritJustification: data.meritJustification,
      workTypeId: data.workTypeId ? preworktype : null,
      statusId: data.statusId ? prestatus : null,
      requestId: data.requestType
        ? data.requestType && {
            id: data.requestType.requestTypeId,
            label: data.requestType.requestTypeName,
          }
        : null,
      //status: data.status ? data.status.status1 : "",
      workType: data.workType ? data.workType.workType1 : "",
      rerun: data.rerun ? data.rerun.rerun1 : "",
      //resolution: data.resolution ? data.resolution.resolutionStatus1 : "",
      reportedBy: data.reportedByNavigation ? prereportedByNavigation : null,
      //reportedBy1: data.reportedByNavigation.reportedBy1,
      addedBy: data.addedBy,
      assignedTo: data.assignedTo ? preassignedTo : null,
      assignedDate: data.assignedDate ? data.assignedDate : null,
      // changes as per SLA requested and FIxed ON 28-05-2024
      // plannedEndDate: data.plannedEndDate ? setplanned() : null,
      plannedEndDate: data.plannedEndDate ? data.plannedEndDate : null,
      resolutionId: data.resolution ? preresolutionstatus : null,
      complexity: data.complexity ? precomplexity : null,
      resolutionDateView: data.resolutionDate ? data.resolutionDate : null,
      actualClosedDate: data.actualClosedDate ? setResStatus() : null,
      updatedBy: null,
      isScrapperRequired: data.isScrapperRequired
        ? data.isScrapperRequired
        : false,
      developerCommentsView: data.developerCommentsView
        ? data.developerComments
        : "",
      developerCommentsNavigation: data.developerCommentsNavigation
        ? data.developerCommentsNavigation
        : [],
    },
    //enableReinitialize: true,

    validate: (values) => validate(values),
    onSubmit: (values) => {
      props.onChangeSave(true);

      formData.append("ticketId", values.ticketId);
      formData.append("dateRaised", values.dateRaised);
      values.dateofFile && formData.append("dateofFile", values.dateofFile);
      formData.append("countryId", values.countryId.id);
      Pname === "LEASE OPERATOR" &&
        formData.append("providerId", values.provider.id);
      Pname === "TRANSACTIONAL ANALYSIS" &&
        formData.append("websiteId", values.website.id);
      for (let i = 0; i < values.make.length; i++) {
        formData.append("makeId", values.make[i].makeId);
      }
      for (let i = 0; i < values.modelId.length; i++) {
        formData.append("modelId", values.modelId[i].modelId);
      }

      formData.append(
        "categoryId",
        values.categoryId ? values.categoryId.id : ""
      );
      for (let i = 0; i < select.files.length; i++) {
        formData.append("files", select.files[i]);
      }
      formData.append("attachments", dataattach ? dataattach : "");
      for (let i = 0; i < select.devfiles.length; i++) {
        formData.append("devfiles", select.devfiles[i]);
      }
      formData.append("devattachments", devdataattach ? devdataattach : "");

      formData.append("typeId", values.typeId.id);
      formData.append("priorityId", values.priorityId.id);
      formData.append("fieldId", values.fieldId ? values.fieldId.id : "");
      formData.append("feedback", values.feedback.trim());
      formData.append("RequestTypeId", values.requestId.id);

      formData.append("rerunId", values.rerunId);
      formData.append(
        "meritJustification",
        values.meritJustification ? values.meritJustification.trim() : ""
      );
      formData.append(
        "workTypeId",
        values.workTypeId ? values.workTypeId.id : ""
      );
      formData.append("statusId", values.statusId ? values.statusId.id : "");
      formData.append("reportedBy", values.reportedBy.id);
      formData.append("addedBy", values.addedBy.toUpperCase());
      formData.append(
        "AssignedTo",
        values.assignedTo ? values.assignedTo.label : ""
      );
      formData.append(
        "AssignedUserId",
        values.assignedTo ? values.assignedTo.id : ""
      );
      // formData.append(
      //   "AssignedDate ",
      //   values.assignedDate
      //     ? moment(values.assignedDate).tz('Europe/London').format("YYYY-MM-DD HH:mm:ss")
      //     : ""
      // );

      // formData.append(
      //   "plannedEndDate",
      //   values.plannedEndDate
      //     ? moment(values.plannedEndDate).tz('Europe/London').format("YYYY-MM-DD HH:mm:ss")
      //     : ""
      // );
      formData.append(
        "AssignedDate",
        values.assignedDate ? values.assignedDate : ""
      );
      formData.append(
        "plannedEndDate",
        values.plannedEndDate ? values.plannedEndDate : ""
      );
      formData.append(
        "ResolutionId",
        values.resolutionId ? values.resolutionId.id : ""
      );
      formData.append(
        "Complexity",
        values.complexity ? values.complexity.id : ""
      );

      // formData.append(
      //   "resolutionDate",
      //   values.resolutionDateView
      //     ? moment(values.resolutionDateView).tz('Europe/London').format("YYYY-MM-DD HH:mm:ss")
      //     : ""
      // );
      // formData.append(
      //   "actualClosedDate",
      //   values.actualClosedDate
      //     ? values.actualClosedDate.tz('Europe/London').format("YYYY-MM-DD HH:mm:ss")
      //     : ""
      // );
      formData.append(
        "resolutionDate",
        values.resolutionDateView ? values.resolutionDateView : ""
      );
      formData.append(
        "actualClosedDate",
        values.actualClosedDate ? values.actualClosedDate : ""
      );
      formData.append("updatedBy", "");
      formData.append(
        "isScrapperRequired",
        values.isScrapperRequired ? values.isScrapperRequired : false
      );
      formData.append(
        "developerComments",
        values.developerCommentsView ? values.developerCommentsView.trim() : ""
      );
      props.EditIssuedata(formData, comments);
      dispatch(saveFiles([]));
      dispatch(saveDevFiles([]));
    },
  });
  useEffect(() => {
    const status_wip = select.resolutionstatus.filter(
      (e) => e.resolutionStatus1.toLowerCase() === "wip"
    );
    localStorage.getItem("status")
      ? console.log()
      : localStorage.setItem("status", JSON.stringify(status_wip));
    setEdit(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isEdit === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isEdit === true
        )
    );
    return () => {
      setEdit(
        selector.roleConfigurations.filter(
          (e) =>
            e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ") && e.isEdit === true
        )
      );
    };
  }, [location.pathname]);
  console.log(edit.length);
  const truncatemodel = (str) => {
    return str ? (str.length > 1 ? str[0] + "," + str[1] + "..." : str) : "";
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const muifilter = createFilterOptions();
  const handleClearOptions = () => formik.setFieldValue("modelId", []);
  const handleSelectAll = (isSelected) =>
    isSelected
      ? formik.setFieldValue("modelId", select.model)
      : handleClearOptions();

  const handleToggleOption = (selectedOptions) => {
    formik.setFieldValue("modelId", selectedOptions);
  };

  const handleToggleSelectAll = () => {
    const f = select.model.length === formik.values.modelId.length;
    handleSelectAll && handleSelectAll(!f);
  };
  const optionRenderer = (props, option, { selected }) => {
    const selectAllProps =
      option.model1 === "Select All" // To control the state of 'select-all' checkbox
        ? { checked: select.model.length === formik.values.modelId.length }
        : {};
    return (
      <li {...props}>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {option.model1}
      </li>
    );
  };

  const userid = localStorage.getItem("userId");
  return (
    <>
      {role == "JATO Admin" || role == "Merit Admin" ? (
        <Box>
          <Grid
            container
            spacing={2.5}
            alignItems="center"
            justifyContent="flex-start"
          >
            {/* Popup Title Start Here */}
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs>
                  {viewissue === true ? (
                    <Typography variant="h6" gutterBottom>
                      Issue Details
                      <span className={classes.artSubText}>(View)</span>
                    </Typography>
                  ) : (
                    <Typography variant="h6" gutterBottom>
                      Issue Details
                      <span className={classes.artSubText}>(Edit)</span>
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={"auto"} textAlign="right">
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs="auto">
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {viewissue === true ? (
                          <Grid item xs="auto">
                            <MaterialUIButton
                              variant="outlined"
                              message="EDIT"
                              disabled={
                                data.actualClosedDate || edit.length === 0
                              }
                              onClick={() => {
                                setViewedit(true);
                                SetViewIssue(false);
                              }}
                              startIcon={<EditIcon />}
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid item xs="auto">
                              <MaterialUIButton
                                variant="outlined"
                                message="SAVE"
                                disabled={props.save}
                                onClick={() => formik.handleSubmit()}
                                startIcon={<SaveOutlinedIcon />}
                              />
                            </Grid>
                            <Grid item xs="auto">
                              {viewissue === true ? (
                                <MaterialUIButton
                                  variant="outlined"
                                  message="CANCEL"
                                  onClick={() => {
                                    cancel();
                                  }}
                                  startIcon={<EventBusyOutlinedIcon />}
                                />
                              ) : (
                                <MaterialUIButton
                                  variant="outlined"
                                  message="CANCEL"
                                  onClick={() => {
                                    JSON.stringify(
                                      formikComment.initialValues
                                    ) ===
                                      JSON.stringify(formikComment.values) &&
                                    JSON.stringify(formik.initialValues) ===
                                      JSON.stringify(formik.values) &&
                                    (data.developerCommentsNavigation
                                      ? data.developerCommentsNavigation.length
                                      : 0) === comments.length &&
                                    select.files.length === 0
                                      ? cancel()
                                      : setShowalert(true);
                                  }}
                                  startIcon={<EventBusyOutlinedIcon />}
                                />
                              )}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <Tooltip title="Close " placement="bottom" arrow>
                        <IconButton color="secondary" aria-label="Close">
                          <CloseIcon
                            onClick={() => {
                              JSON.stringify(formikComment.initialValues) ===
                                JSON.stringify(formikComment.values) &&
                              JSON.stringify(formik.initialValues) ===
                                JSON.stringify(formik.values) &&
                              (data.developerCommentsNavigation
                                ? data.developerCommentsNavigation.length
                                : 0) === comments.length &&
                              select.files.length === 0
                                ? cancel()
                                : setShowalert(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <AlertDialog
                      open={showalert}
                      onClose={() => setShowalert(false)}
                      // open={isDeleted}
                      title={"Confirmation"}
                      // maxWidth={"sm"}
                      description={`Do you want to discard the changes ?`}
                      action={
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="right"
                          spacing={2}
                          className={classes.alertDialogbtn}
                        >
                          <Grid item xs={"auto"}>
                            <MaterialUIButton
                              variant="outlined"
                              onClick={props.close}
                              message="Yes"
                              startIcon={<DoneIcon />}
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <MaterialUIButton
                              variant="outlined"
                              onClick={() => {
                                setShowalert(false);
                              }}
                              message="No"
                              startIcon={<CloseIcon />}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Popup Title End Here */}

            <Grid item xs={12}>
              <Box className={classes.borderLine}>
                <Grid
                  container
                  spacing={2.5}
                  alignItems="top"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      className={classes.titleLine}
                    >
                      <span className={classes.artLine}> </span>Issue Details
                      <span className={classes.artLine}></span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Ticket Id
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>{data.ticketId ? data.ticketId : ""}</strong>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Textbox
                          size="small"
                          readOnly
                          label="Ticket Id"
                          name="ticket Id"
                          value={data.ticketId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ticketId &&
                            Boolean(formik.errors.ticketId)
                          }
                          helperText={
                            formik.touched.ticketId && formik.errors.ticketId
                          }
                          fullWidth
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Jira Ticket Id
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>{data.jiraId ? data.jiraId : ""}</strong>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Textbox
                          size="small"
                          readOnly
                          label="Jira Ticket Id"
                          name="jiraId"
                          value={data.jiraId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.jiraId &&
                            Boolean(formik.errors.jiraId)
                          }
                          helperText={
                            formik.touched.jiraId && formik.errors.jiraId
                          }
                          fullWidth
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Date Raised
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.dateRaised
                                  ? moment(data.dateRaised).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datetimepicker
                          readOnly
                          name="dateRaised"
                          value={data.dateRaised}
                          onChange={(e) =>
                            formik.setFieldValue("dateRaised", e)
                          }
                          error={
                            formik.touched.dateRaised &&
                            Boolean(formik.errors.dateRaised)
                          }
                          helperText={
                            formik.touched.dateRaised &&
                            formik.errors.dateRaised
                          }
                          size="small"
                          fullWidth
                          label="Date Raised"
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          File Uploaded Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.dateofFile
                                  ? moment(data.dateofFile).format(
                                      "DD/MM/YYYY "
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datepicker
                          readOnly
                          onKeyDown={onKeyDown}
                          max={moment().format("YYYY-MM-DD")}
                          name="dateofFile"
                          value={formik.values.dateofFile}
                          onChange={(e) => {
                            formik.setFieldValue("dateofFile", e);
                          }}
                          error={
                            formik.touched.dateofFile &&
                            Boolean(formik.errors.dateofFile)
                          }
                          helperText={
                            formik.touched.dateofFile &&
                            formik.errors.dateofFile
                          }
                          size="small"
                          fullWidth
                          label="File Uploaded Date"
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Country
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.country ? data.country.countryCode : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          required
                          readOnly={data.assignedDate ? true : false}
                          value={formik.values.countryId}
                          onChange={(_, data) => {
                            formik.setFieldValue("countryId", data);
                            formik.setFieldValue("make", []);
                            formik.setFieldValue("modelId", []);
                            dispatch(makeDependency([data.id], id)).then(
                              (res) => {
                                if (res.data.statusCode === 200) {
                                  if (res.data.result === "No records found") {
                                    dispatch(getMake([]));
                                  } else {
                                    dispatch(getMake(res.data.result));
                                  }
                                } else {
                                  dispatch(getMake([]));
                                }
                              }
                            );
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.countryId &&
                            Boolean(formik.errors.countryId)
                          }
                          helperText={
                            formik.touched.countryId && formik.errors.countryId
                          }
                          labelId="Country"
                          id="Country"
                          name="countryId"
                          label="Country"
                          options={select.country.map((e) => {
                            return {
                              label: e.countryCode + " - " + e.region,
                              id: e.countryId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  {Pname === "LEASE OPERATOR" ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {viewissue === true ? (
                        <>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Leasing Operator
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {data.provider
                              ? data.provider.leasingProviderName
                              : ""}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Autocompletedropdown
                            required
                            // readOnly={true}
                            value={formik.values.provider}
                            onChange={(_, data) => {
                              formik.setFieldValue("provider", data);
                              dispatch(
                                priorityDropdownLease(data.priorityId, id)
                              ).then((res) => {
                                if (res.status === 200) {
                                  if (
                                    res.data.result === "No records found" ||
                                    Object.keys(res.data).length === 0
                                  ) {
                                    formik.setFieldValue("priorityId", null);
                                  } else {
                                    console.log(res.data);

                                    formik.setFieldValue("priorityId", {
                                      label: res.data.issuePriority1,
                                      id: res.data.priorityId,
                                    });
                                  }
                                } else {
                                  formik.setFieldValue("priorityId", null);
                                }
                              });
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            labelId="Provider"
                            id="Provider"
                            name="provider"
                            label="Leasing Provider"
                            getOptionLabel={(e) => e.leasingProviderName}
                            error={
                              formik.touched.provider &&
                              Boolean(formik.errors.provider)
                            }
                            helperText={
                              formik.touched.provider && formik.errors.provider
                            }
                            options={select.provider.map((e) => {
                              return {
                                priorityId: e.priorityId,
                                label: e.leasingProviderName,
                                id: e.providerId,
                              };
                            })}
                          />
                        </>
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {Pname === "TRANSACTIONAL ANALYSIS" ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {viewissue === true ? (
                        <>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Website
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {data.website ? data.website.website1 : ""}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Autocompletedropdown
                            required
                            value={formik.values.website}
                            onChange={(_, data) => {
                              formik.setFieldValue("website", data);
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            labelId="Website"
                            id="Website"
                            name="website"
                            label="Website"
                            getOptionLabel={(e) => e.website1}
                            error={
                              formik.touched.website &&
                              Boolean(formik.errors.website)
                            }
                            helperText={
                              formik.touched.website && formik.errors.website
                            }
                            options={select.website.map((e) => {
                              return {
                                label: e.website1,
                                id: e.websiteId,
                              };
                            })}
                          />
                        </>
                      )}
                    </Grid>
                  ) : (
                    <> </>
                  )}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Make
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.fkMakeIssueTracker ? (
                            <>
                              <Tooltip
                                title={data.fkMakeIssueTracker.map((e, i) => (
                                  <React.Fragment key={i}>
                                    {e.make.make1}
                                    {i !== data.fkMakeIssueTracker.length - 1
                                      ? ", "
                                      : ""}
                                  </React.Fragment>
                                ))}
                                placement="top"
                                arrow
                              >
                                <Typography variant="body2" gutterBottom>
                                  {data.fkMakeIssueTracker ? (
                                    truncatemodel(
                                      data.fkMakeIssueTracker.map(
                                        (e) => e.make.make1
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </Typography>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <MultipleAutocomplete
                          id="Make"
                          required
                          label="Make"
                          labelId="Make"
                          disabled={!formik.values.countryId ? true : false}
                          name="make"
                          value={formik.values.make}
                          filterOptions={(options, params) => {
                            const filtered = muifilter(options, params);
                            return [{ makeId: 0, make1: "ALL" }, ...filtered];
                          }}
                          onChange={(_, e, value) => {
                            if (e.some((element) => element.make1 === "ALL")) {
                              dispatch(
                                modelDependency(
                                  select.make.map((f) => f.makeId),
                                  id
                                )
                              ).then((res) => {
                                if (res.data.statusCode === 200) {
                                  if (res.data.result === "No records found") {
                                    dispatch(getModel([]));
                                  } else {
                                    dispatch(getModel(res.data.result));
                                  }
                                } else {
                                  dispatch(getModel([]));
                                }
                              });
                              dispatch(
                                priorityDropdownNew(
                                  {
                                    countryId: formik.values.countryId.id,
                                    makeIds: select.make.map((f) => f.makeId),
                                  },
                                  id
                                )
                              ).then((res) => {
                                if (res.status === 200) {
                                  if (
                                    res.data.result === "No records found" ||
                                    Object.keys(res.data).length === 0
                                  ) {
                                    Pname !== "LEASE OPERATOR" &&
                                      formik.setFieldValue("priorityId", null);
                                  } else {
                                    console.log(res.data);

                                    formik.setFieldValue("priorityId", {
                                      label: res.data.issuePriority1,
                                      id: res.data.priorityId,
                                    });
                                  }
                                } else {
                                  Pname !== "LEASE OPERATOR" &&
                                    formik.setFieldValue("priorityId", null);
                                }
                              });
                            } else {
                              dispatch(
                                modelDependency(
                                  e.map((f) => f.makeId),
                                  id
                                )
                              ).then((res) => {
                                if (res.data.statusCode === 200) {
                                  if (res.data.result === "No records found") {
                                    dispatch(getModel([]));
                                  } else {
                                    dispatch(getModel(res.data.result));
                                  }
                                } else {
                                  dispatch(getModel([]));
                                }
                              });
                              dispatch(
                                priorityDropdownNew(
                                  {
                                    countryId: formik.values.countryId.id,
                                    makeIds: e.map((f) => f.makeId),
                                  },
                                  id
                                )
                              ).then((res) => {
                                if (res.status === 200) {
                                  if (
                                    res.data.result === "No records found" ||
                                    Object.keys(res.data).length === 0
                                  ) {
                                    Pname !== "LEASE OPERATOR" &&
                                      formik.setFieldValue("priorityId", null);
                                  } else {
                                    console.log(res);
                                    formik.setFieldValue("priorityId", {
                                      label: res.data.issuePriority1,
                                      id: res.data.priorityId,
                                    });
                                  }
                                } else {
                                  Pname !== "LEASE OPERATOR" &&
                                    formik.setFieldValue("priorityId", null);
                                }
                              });
                            }
                            formik.setFieldValue("modelId", []);
                            if (
                              value === "selectOption" ||
                              value === "removeOption"
                            ) {
                              if (e.find((option) => option.make1 === "ALL")) {
                                let result = [];
                                result = select.make.filter(
                                  (el) => el.make1 !== "ALL"
                                );
                                formik.setFieldValue("make", result);
                              } else {
                                handleToggleOptionm && handleToggleOptionm(e);

                                formik.setFieldValue("make", e);
                              }
                            } else if (value === "clear") {
                              formik.setFieldValue("make", []);
                            }
                            if (e.find((option) => option.make1 === "All")) {
                              select.make.length === formik.values.make?.length
                                ? formik.setFieldValue("make", [])
                                : formik.setFieldValue("make", select.make);
                            }
                          }}
                          renderTags={(value, getTagProps) =>
                            value.length === select.make.length ? (
                              <Chip
                                size="small"
                                variant="outlined"
                                label="ALL"
                                //{...getTagProps({ index })}
                              />
                            ) : (
                              value.map((option, index) => (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={option.make1}
                                  //{...getTagProps({ index })}
                                />
                              ))
                            )
                          }
                          //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
                          isOptionEqualToValue={(option, value) => {
                            return option.makeId === value.makeId;
                          }}
                          // getOptionSelected={(option, value) => option.value === value.value}
                          error={
                            formik.touched.make && Boolean(formik.errors.make)
                          }
                          helperText={formik.touched.make && formik.errors.make}
                          placeholder="Make"
                          limitTags={4}
                          options={select.make}
                          getOptionLabel={(e) => e.make1}
                          renderOption={optionRendererm}
                        />
                      </>
                    )}
                  </Grid>

                  {viewissue === true ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Model
                      </Typography>
                      {data.fkIssueTracker ? (
                        <>
                          <Tooltip
                            title={data.fkIssueTracker.map((e, i) => (
                              <React.Fragment key={i}>
                                {e.model.model1}
                                {i !== data.fkIssueTracker.length - 1
                                  ? ", "
                                  : ""}
                              </React.Fragment>
                            ))}
                            placement="top"
                            arrow
                          >
                            <Typography variant="body2" gutterBottom>
                              {truncatemodel(
                                data.fkIssueTracker.map((e) => e.model.model1)
                              )}
                            </Typography>
                          </Tooltip>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4} lg={6}>
                      <MultipleAutocomplete
                        id="Model"
                        label="Model"
                        required={Pname === "OEMS"}
                        labelId="Model"
                        readOnly={data.assignedDate ? true : false}
                        disabled={!formik.values.make ? true : false}
                        name="modelId"
                        value={formik.values.modelId}
                        filterOptions={(options, params) => {
                          const filtered = muifilter(options, params);
                          return [
                            { modelId: 0, model1: "Select All" },
                            ...filtered,
                          ];
                        }}
                        onChange={(_, e, value) => {
                          if (
                            value === "selectOption" ||
                            value === "removeOption"
                          ) {
                            if (
                              e.find((option) => option.model === "Select All")
                            ) {
                              let result = [];
                              result = select.model.filter(
                                (el) => el.model1 !== "Select All"
                              );
                              formik.setFieldValue("modelId", result);
                            } else {
                              handleToggleOption && handleToggleOption(e);

                              formik.setFieldValue("modelId", e);
                            }
                          } else if (value === "clear") {
                            formik.setFieldValue("modelId", []);
                          }
                          if (
                            e.find((option) => option.model1 === "Select All")
                          ) {
                            select.model.length === formik.values.modelId.length
                              ? formik.setFieldValue("modelId", [])
                              : formik.setFieldValue("modelId", select.model);
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              size="small"
                              variant="outlined"
                              label={option.model1}
                              //{...getTagProps({ index })}
                            />
                          ))
                        }
                        //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
                        isOptionEqualToValue={(option, value) => {
                          return option.modelId === value.modelId;
                        }}
                        // getOptionSelected={(option, value) => option.value === value.value}
                        error={
                          formik.touched.modelId &&
                          Boolean(formik.errors.modelId)
                        }
                        helperText={
                          formik.touched.modelId && formik.errors.modelId
                        }
                        placeholder="Model"
                        limitTags={4}
                        options={select.model}
                        getOptionLabel={(e) => e.model1}
                        renderOption={optionRenderer}
                      />
                    </Grid>
                  )}

                  {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Category
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.category ? data.category.issueCategory1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          fullWidth
                          labelId="Issue Category"
                          id="Issue Category"
                          name="categoryId"
                          label="Issue Category"
                          value={formik.values.categoryId}
                          onChange={(_, data) => {
                            formik.setFieldValue("categoryId", data);
                            //formik.setFieldValue("issueType1", data);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.categoryId &&
                            Boolean(formik.errors.categoryId)
                          }
                          helperText={
                            formik.touched.categoryId &&
                            formik.errors.categoryId
                          }
                          options={select.category.map((e) => {
                            return {
                              label: e.issueCategory1,
                              id: e.categoryId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.type ? data.type.issueType1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          required
                          fullWidth
                          name="typeId"
                          readOnly={data.assignedDate ? true : false}
                          value={formik.values.typeId}
                          onChange={(_, data) => {
                            formik.setFieldValue("typeId", data);
                            formik.setFieldValue("issueType1", data);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.typeId &&
                            Boolean(formik.errors.typeId)
                          }
                          helperText={
                            formik.touched.typeId && formik.errors.typeId
                          }
                          labelId="Issue Type"
                          id="Issue Type"
                          label="Issue Type"
                          options={select.type.map((e) => {
                            return {
                              label: e.issueType1,
                              id: e.typeId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  {/*  */}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Business Priority
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.priority ? data.priority.issuePriority1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          fullWidth
                          required
                          name="priorityId"
                          readOnly
                          // readOnly={data.assignedDate ? true : false}
                          onChange={(_, e, value) => {
                            formik.setFieldValue("priorityId", e);
                            // changes as per SLA requested and FIxed ON 28-05-2024

                            // if (e.label.toLowerCase() === "critical")
                            //   formik.setFieldValue(
                            //     "plannedEndDate",
                            //     moment().add(1, "days")
                            //   );

                            // if (e.label.toLowerCase() === "high")
                            //   formik.setFieldValue(
                            //     "plannedEndDate",
                            //     moment().add(2, "days")
                            //   );
                            // if (e.label.toLowerCase() === "medium")
                            //   formik.setFieldValue(
                            //     "plannedEndDate",
                            //     moment().add(3, "days")
                            //   );
                            // if (e.label.toLowerCase() === "low")
                            //   formik.setFieldValue(
                            //     "plannedEndDate",
                            //     moment().add(4, "days")
                            //   );
                            // if (e === null && value === "clear") {
                            //   // formik.setFieldValue("priorityId",e);
                            //   formik.setFieldValue("plannedEndDate", null);
                            // }
                          }}
                          value={formik.values.priorityId}
                          error={
                            formik.touched.priorityId &&
                            Boolean(formik.errors.priorityId)
                          }
                          helperText={
                            formik.touched.priorityId &&
                            formik.errors.priorityId
                          }
                          labelId="Business Priority"
                          id="Business Priority"
                          label="Business Priority"
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          getOptionLabel={(e) => e.e.issuePriority1}
                          options={select.priority.map((e) => {
                            return {
                              label: e.issuePriority1,
                              id: e.priorityId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Field
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.field ? data.field.issueField1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          value={formik.values.fieldId}
                          readOnly={data.assignedDate ? true : false}
                          onChange={(_, data) => {
                            formik.setFieldValue("fieldId", data);
                            formik.setFieldValue("issueField1", data);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.fieldId &&
                            Boolean(formik.errors.fieldId)
                          }
                          helperText={
                            formik.touched.fieldId && formik.errors.fieldId
                          }
                          labelId="Issue Field"
                          id="Issue Field"
                          label="Issue Field"
                          fullWidth
                          name="fieldId"
                          options={select.field.map((e) => {
                            return {
                              label: e.issueField1,
                              id: e.fieldId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue/Request Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.requestType
                            ? data.requestType.requestTypeName
                            : ""}
                        </Typography>
                      </>
                    ) : (
                      <Autocompletedropdown
                        name="requestId"
                        required
                        readOnly={data.assignedDate ? true : false}
                        value={formik.values.requestId}
                        onChange={(_, data) =>
                          formik.setFieldValue("requestId", data)
                        }
                        error={
                          formik.touched.requestId &&
                          Boolean(formik.errors.requestId)
                        }
                        helperText={
                          formik.touched.requestId && formik.errors.requestId
                        }
                        labelId="Request ID"
                        id="request id"
                        label="Issue/Request  Type"
                        isOptionEqualToValue={(option, value) => {
                          return option.id === value.id;
                        }}
                        getOptionLabel={(e) => e.requestTypeName}
                        options={select.requestType.map((e) => {
                          return {
                            label: e.requestTypeName,
                            id: e.requestTypeId,
                          };
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Reported By
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.reportedByNavigation
                            ? data.reportedByNavigation.reportedBy1
                            : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          required
                          readOnly={data.assignedDate ? true : false}
                          value={formik.values.reportedBy}
                          onChange={(_, data) => {
                            formik.setFieldValue("reportedBy", data);
                            // formik.setFieldValue("reportedBy", data.id);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.reportedBy &&
                            Boolean(formik.errors.reportedBy)
                          }
                          helperText={
                            formik.touched.reportedBy &&
                            formik.errors.reportedBy
                          }
                          //labelId="Reported By"
                          id="ReportedBy"
                          name="reportedBy"
                          label="Reported By"
                          options={select.reportedBy.map((e) => {
                            return {
                              label: e.reportedBy1,
                              id: e.reportedById,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Added By
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.addedBy ? data.addedBy : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Textbox
                          readOnly
                          label="Added By"
                          name="addedBy"
                          value={data.addedBy}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.addedBy &&
                            Boolean(formik.errors.addedBy)
                          }
                          helperText={
                            formik.touched.addedBy && formik.errors.addedBy
                          }
                          fullWidth
                        />
                      </>
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Work Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.workType ? data.workType.workType1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          required={role !== "JATO Admin"}
                          onChange={(_, data) => {
                            //formik.setFieldValue("workType", data);
                            formik.setFieldValue("workTypeId", data);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option?.id === value?.id;
                          }}
                          value={formik.values.workTypeId}
                          error={
                            formik.touched.workTypeId &&
                            Boolean(formik.errors.workTypeId)
                          }
                          helperText={
                            formik.touched.workTypeId &&
                            formik.errors.workTypeId
                          }
                          id="workTypeId"
                          name="workTypeId"
                          label="Work Type"
                          options={select.worktype.map((e) => {
                            return {
                              label: e.workType1,
                              id: e.workTypeId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid> */}

                  {viewissue === true ? (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Feedback"
                          fullWidth
                          name="feedback"
                          value={formik.values.feedback}
                          onChange={(data) => {
                            formik.setFieldValue("feedback", data.target.value);
                          }}
                          helperText={
                            formik.touched.feedback && formik.errors.feedback
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Textbox
                          readOnly={data.assignedDate ? true : false}
                          required
                          multiline
                          rows={3}
                          label="Feedback"
                          fullWidth
                          name="feedback"
                          value={formik.values.feedback}
                          onChange={(data) => {
                            formik.setFieldValue("feedback", data.target.value);
                          }}
                          error={
                            formik.touched.feedback &&
                            Boolean(formik.errors.feedback)
                          }
                          helperText={
                            formik.touched.feedback && formik.errors.feedback
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={12} lg={6}>
                    {viewissue === true ? (
                      <>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Merit Justification"
                          name="meritJustification"
                          value={formik.values.meritJustification}
                          onChange={(data) => {
                            formik.setFieldValue(
                              "meritJustification",
                              data.target.value
                            );
                          }}
                          error={
                            formik.touched.meritJustification &&
                            Boolean(formik.errors.meritJustification)
                          }
                          helperText={
                            formik.touched.meritJustification &&
                            formik.errors.meritJustification
                          }
                          fullWidth
                        />
                      </>
                    ) : (
                      <>
                        <Textbox
                          multiline
                          rows={3}
                          //readOnly={data.assignedDate ? true : false}
                          label="Merit Justification"
                          name="meritJustification"
                          value={formik.values.meritJustification}
                          onChange={(data) => {
                            formik.setFieldValue(
                              "meritJustification",
                              data.target.value
                            );
                          }}
                          error={
                            formik.touched.meritJustification &&
                            Boolean(formik.errors.meritJustification)
                          }
                          helperText={
                            formik.touched.meritJustification &&
                            formik.errors.meritJustification
                          }
                          fullWidth
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Rerun
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.rerun ? data.rerun.rerun1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          required
                          readOnly={data.assignedDate ? true : false}
                          onChange={(_, data) => {
                            formik.setFieldValue("rerun", data);
                            formik.setFieldValue("rerunId", data.id);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.rerun && Boolean(formik.errors.rerun)
                          }
                          helperText={
                            formik.touched.rerun && formik.errors.rerun
                          }
                          value={formik.values.rerun}
                          id="rerunId"
                          name="rerun"
                          label="Rerun"
                          options={select.rerun.map((e) => {
                            return {
                              label: e.rerun1,
                              id: e.rerunId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Status
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.status ? data.status.status1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          //readOnly={data.assignedDate ? true : false}
                          required={role !== "JATO Admin"}
                          onChange={(_, data) => {
                            formik.setFieldValue("statusId", data);
                            //formik.setFieldValue("statusId", data.id);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          value={formik.values.statusId}
                          error={
                            formik.touched.statusId &&
                            Boolean(formik.errors.statusId)
                          }
                          helperText={
                            formik.touched.statusId && formik.errors.statusId
                          }
                          id="statusId"
                          name="statusId"
                          label="Status"
                          options={select.status.map((e) => {
                            return {
                              label: e.status1,
                              id: e.statusId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>

                  {viewissue === true || data.assignedDate ? (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Attachment(s)
                        </Typography>
                      </Grid>
                      {data.attachments ? (
                        data.attachments.split(",").length > 0 ? (
                          <>
                            {data.attachments.split(",").map((e) => (
                              <>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Tooltip placement="top" title={e} arrow>
                                    <Typography
                                      gutterBottom
                                      noWrap
                                      variant="body2"
                                    >
                                      <img
                                        src={pdfIcon}
                                        width="24"
                                        alt="pdf"
                                        style={{
                                          marginBottom: -5,
                                          marginRight: 4,
                                        }}
                                      />
                                      {e}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                              </>
                            ))}
                          </>
                        ) : (
                          <Grid item xs={12} md={6} lg={3}>
                            <Tooltip
                              placement="top"
                              title={data.attachments}
                              arrow
                            >
                              <Typography gutterBottom noWrap variant="body2">
                                <img
                                  src={pdfIcon}
                                  width="24"
                                  alt="pdf"
                                  style={{
                                    marginBottom: -5,
                                    marginRight: 4,
                                  }}
                                />
                                {data.attachments}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ marginTop: "12px", marginBottom: "5px" }}
                        >
                          Already Uploaded File(s)
                        </Typography>
                        <div className={classes.moreAttachments}>
                          <Grid
                            container
                            spacing={2}
                            alignItems="top"
                            justifyContent="flex-start"
                          >
                            {dataattach ? (
                              dataattach.split(",").length > 0 ? (
                                <>
                                  {dataattach.split(",").map((e) => (
                                    <Grid item xs={12} md={4} lg={3}>
                                      <List className={classes.uploadFiles}>
                                        <ListItem
                                          className={classes.listItemLastStyle}
                                          //
                                          secondaryAction={
                                            <IconButton
                                              disabled={
                                                data.assignedDate ? true : false
                                              }
                                              onClick={
                                                () => handleDelete(e)
                                                //setShowDeleteIssue(true)
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <img
                                                src={remove}
                                                width="20"
                                                alt="pdf"
                                              />
                                            </IconButton>
                                          }
                                        >
                                          <ListItemAvatar>
                                            <img
                                              src={pdfIcon}
                                              width="24"
                                              alt="pdf"
                                            />
                                          </ListItemAvatar>

                                          <ListItemText
                                            onClick={() => {
                                              openFileInNewTab(e);
                                            }}
                                            primary={`${truncateFileName(e)}`}
                                          />
                                        </ListItem>
                                      </List>
                                    </Grid>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <DropzoneEdit
                          disabled={data.assignedDate ? true : false}
                          margin="normal"
                          showSelectedFile={true}
                          isFileCleared={isFileCleared}
                          onFilesAdded={(e, fileUpload) =>
                            console.log(fileUpload)
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.borderLine}>
                <Grid
                  container
                  spacing={2}
                  alignItems="top"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Grid item xs={"auto"} textAlign="right">
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Grid item xs="auto">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            className={classes.titleLine}
                          >
                            <span className={classes.artLine}> </span>
                            Resolution Details
                            <span className={classes.artLine}></span>
                          </Typography>
                        </Grid>
                        <Grid item xs="auto"></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to First Response TAT
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data?.firstResponseTAT
                                  ? moment(data.firstResponseTAT).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datetimepicker
                          onKeyDown={onKeyDown}
                          name="TAT"
                          //min={moment().format("YYYY-MM-DD")}
                          // changes as per SLA requested and FIxed ON 28-05-2024
                          value={
                            data?.firstResponseTAT
                              ? data?.firstResponseTAT
                              : null
                          }
                          // onChange={(e) => {
                          //   formik.setFieldValue("plannedEndDate", e);
                          // }}
                          // error={
                          //   formik.touched.plannedEndDate &&
                          //   Boolean(formik.errors.plannedEndDate)
                          // }
                          // helperText={
                          //   formik.touched.plannedEndDate &&
                          //   formik.errors.plannedEndDate
                          // }
                          // fullWidth
                          readOnly
                          label="Time to First Response TAT"
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to First Response
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.plannedEndDate
                                  ? moment(data.plannedEndDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datetimepicker
                          onKeyDown={onKeyDown}
                          name="plannedEndDate"
                          //min={moment().format("YYYY-MM-DD")}
                          // changes as per SLA requested and FIxed ON 28-05-2024
                          value={formik.values.plannedEndDate}
                          onChange={(e) => {
                            formik.setFieldValue("plannedEndDate", e);
                          }}
                          error={
                            formik.touched.plannedEndDate &&
                            Boolean(formik.errors.plannedEndDate)
                          }
                          helperText={
                            formik.touched.plannedEndDate &&
                            formik.errors.plannedEndDate
                          }
                          fullWidth
                          readOnly
                          label="Time to First Response"
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Assigned To
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.assignedTo ? data.assignedTo : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          onChange={(_, data1, value) => {
                            formik.setFieldValue("assignedTo", data1);
                            if (data1 == null && value === "clear") {
                              // changes as per SLA requested and FIxed ON 28-05-2024
                              formik.setFieldValue("assignedDate", null);
                              // formik.setFieldValue("priorityId",e);
                              if (data.plannedEndDate === null) {
                                formik.setFieldValue("plannedEndDate", null);
                              }

                              const status = select.resolutionstatus.filter(
                                (e) =>
                                  e.resolutionStatus1.toLowerCase() ===
                                  "yet to start"
                              );
                              console.log(select.resolutionstatus);
                              status.map((e) => {
                                formik.setFieldValue("resolutionId", {
                                  label: e.resolutionStatus1,
                                  id: e.resolutionId,
                                });
                              });
                              // changes as per SLA requested and FIxed ON 28-05-2024
                            } else {
                              if (formik.values.categoryId) {
                                const status = select.resolutionstatus.filter(
                                  (e) =>
                                    e.resolutionStatus1.toLowerCase() === "rca"
                                );
                                console.log(select.resolutionstatus);
                                status.map((e) => {
                                  formik.setFieldValue("resolutionId", {
                                    label: e.resolutionStatus1,
                                    id: e.resolutionId,
                                  });
                                });
                              } else {
                                const status = select.resolutionstatus.filter(
                                  (e) =>
                                    e.resolutionStatus1.toLowerCase() ===
                                    "yet to start"
                                );
                                console.log(select.resolutionstatus);
                                status.map((e) => {
                                  formik.setFieldValue("resolutionId", {
                                    label: e.resolutionStatus1,
                                    id: e.resolutionId,
                                  });
                                });
                              }
                              // changes as per SLA requested and FIxed ON 22-07-2024
                              // if (
                              //   data.plannedEndDate === null ||
                              //   formik.values.plannedEndDate === null
                              // ) {
                              //   formik.setFieldValue(
                              //     "plannedEndDate",
                              //     moment().add(0, "days")
                              //   );
                              // }
                              if (data.assignedTo !== data1.label) {
                                formik.setFieldValue(
                                  "assignedDate",
                                  moment()
                                    .utc()

                                    .format("YYYY-MM-DD HH:mm:ss")
                                );
                              } else {
                                formik.setFieldValue(
                                  "assignedDate",
                                  data.assignedDate
                                );
                              }
                            }
                            //formik.setFieldValue("assignedToView", data.id);
                          }}
                          /* isOptionEqualToValue={(option, value) => {
                                              return option.id === value.id;
                                            }} */
                          value={formik.values.assignedTo}
                          id="userId"
                          name="assignedTo"
                          label="Assigned To"
                          error={
                            formik.touched.assignedTo &&
                            Boolean(formik.errors.assignedTo)
                          }
                          options={
                            role.toLowerCase().includes("merit")
                              ? select.meritusers.map((e) => ({
                                  label: e.name,
                                  id: e.userId,
                                }))
                              : select.users.map((e) => ({
                                  label: e.name,
                                  id: e.userId,
                                }))
                          }
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Assigned Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.assignedDate
                                  ? moment(data.assignedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datetimepicker
                          name="assignedDate"
                          value={
                            formik.values.assignedDate
                              ? formik.values.assignedDate
                              : null
                          }
                          readOnly
                          size="small"
                          fullWidth
                          label="Assigned Date"
                        />
                        {/* <Datepicker
                          name="assignedDateView"
                          value={
                            formik.values.assignedDateView
                              ? formik.values.assignedDateView
                              : null
                          }
                          readOnly
                          size="small"
                          fullWidth
                          label="Assigned Date"
                        /> */}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Category
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.category ? data.category.issueCategory1 : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          fullWidth
                          labelId="Issue Category"
                          id="Issue Category"
                          name="categoryId"
                          label="Issue Category"
                          value={formik.values.categoryId}
                          onChange={(_, data, value) => {
                            dispatch(fetchResolutionstatus([]));
                            formik.setFieldValue("categoryId", data);

                            dispatch(Status_on(data.label, id)).then((res) => {
                              if (res.data.statusCode === 200) {
                                if (res.data.result === "No records found") {
                                  dispatch(fetchResolutionstatus([]));
                                } else
                                  dispatch(
                                    fetchResolutionstatus(res.data.result)
                                  );
                              } else {
                                dispatch(fetchResolutionstatus([]));
                              }
                            });
                            const status = select.resolutionstatus.filter(
                              (e) => e.resolutionStatus1.toLowerCase() === "rca"
                            );
                            status.map((e) => {
                              formik.setFieldValue("resolutionId", {
                                label: e.resolutionStatus1,
                                id: e.resolutionId,
                              });
                            });
                            if (value === "clear") {
                              dispatch(Status_on(data.label, id)).then(
                                (res) => {
                                  if (res.data.statusCode === 200) {
                                    if (
                                      res.data.result === "No records found"
                                    ) {
                                      dispatch(fetchResolutionstatus([]));
                                    } else
                                      dispatch(
                                        fetchResolutionstatus(res.data.result)
                                      );
                                  } else {
                                    dispatch(fetchResolutionstatus([]));
                                  }
                                }
                              );
                            }
                            //formik.setFieldValue("issueType1", data);
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          error={
                            formik.touched.categoryId &&
                            Boolean(formik.errors.categoryId)
                          }
                          helperText={
                            formik.touched.categoryId &&
                            formik.errors.categoryId
                          }
                          options={select.category.map((e) => {
                            return {
                              label: e.issueCategory1,
                              id: e.categoryId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Scrapper Complexity
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.complexity ? data.complexity : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          readOnly={!formik.values.assignedTo ? true : false}
                          onChange={(_, e, value) => {
                            formik.setFieldValue("complexity", e);
                            if (e == null && value == "clear") {
                              console.log("hey2", e, value);
                              formik.setFieldValue("resolutionId", null);

                              formik.setFieldValue("plannedEndDate", null);
//trigger
                              // formik.setFieldValue("priorityId",e);
                              formik.setFieldValue("resolutionDateView", null);
                            } else {
                              if (
                                data.plannedEndDate === null ||
                                formik.values.plannedEndDate === null
                              ) { 
                                formik.setFieldValue(
                                  "plannedEndDate",
                                  moment()
                                    .utc()

                                    .add(0, "days")
                                    .format("YYYY-MM-DD HH:mm:ss")
                                );
                              }

// trigger qa
                              // trigger check

                              // trigger qa
                              // trigger check
                              formik.setFieldValue(
                                "resolutionDateView",
                                addDecimalBusinessDays(
                                  formik.values.assignedDate,
                                  // moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                  getTotalDays(
                                    e.label,
                                    formik.values.priorityId.label
                                  ),
                                  holiday.map((e) =>
                                    moment(e.date).format("YYYY-MM-DD HH:mm:ss")
                                  )
                                )
                              );
                              JSON.parse(localStorage.getItem("status")).map(
                                (e) => {
                                  formik.setFieldValue("resolutionId", {
                                    label: e.resolutionStatus1,
                                    id: e.resolutionId,
                                  });
                                }
                              );
                              // if (e.label.toLowerCase() === "extreme") {

                              // }
                              // if (e.label.toLowerCase() === "high") {

                              //   JSON.parse(localStorage.getItem("status")).map(
                              //     (e) => {
                              //       formik.setFieldValue("resolutionId", {
                              //         label: e.resolutionStatus1,
                              //         id: e.resolutionId,
                              //       });
                              //     }
                              //   );
                              // }
                              // if (e.label.toLowerCase() === "medium") {

                              //   JSON.parse(localStorage.getItem("status")).map(
                              //     (e) => {
                              //       formik.setFieldValue("resolutionId", {
                              //         label: e.resolutionStatus1,
                              //         id: e.resolutionId,
                              //       });
                              //     }
                              //   );
                              // }
                              // if (e.label.toLowerCase() === "low") {

                              //   JSON.parse(localStorage.getItem("status")).map(
                              //     (e) => {
                              //       formik.setFieldValue("resolutionId", {
                              //         label: e.resolutionStatus1,
                              //         id: e.resolutionId,
                              //       });
                              //     }
                              //   );
                              // }
                            }
                          }}
                          value={formik.values.complexity}
                          id="complexity"
                          name="complexity"
                          label="Scrapper Complexity"
                          error={
                            formik.touched.complexity &&
                            Boolean(formik.errors.complexity)
                          }
                          helperText={
                            formik.touched.complexity &&
                            formik.errors.complexity
                          }
                          options={select.developercomplexity.map((e) => {
                            return {
                              label: e,
                              id: e,
                            };
                          })}
                          getOptionDisabled={(option) => formik.values.complexity?.id === option.id}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                      </>
                    )}
                  </Grid>

                  {viewissue === true ? (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to Resolution
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.resolutionDate
                                  ? moment(data.resolutionDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Datetimepicker
                        readOnly
                        name="resolutionDateView"
                        // min={moment().format("YYYY-MM-DD")}
                        value={
                          formik.values.resolutionDateView
                            ? formik.values.resolutionDateView
                            : null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("resolutionDateView", e);
                        }}
                        fullWidth
                        label="Time to Resolution"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Resolution Status
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.resolution
                            ? data.resolution.resolutionStatus1
                            : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Autocompletedropdown
                          readOnly={!formik.values.complexity ? true : false}
                          onChange={(_, data, value) => {
                            //formik.setFieldValue("resolution", data);
                            formik.setFieldValue("resolutionId", data);
                            if (data == null && value == "clear") {
                              // formik.setFieldValue("priorityId",e);
                              formik.setFieldValue("actualClosedDate", null);
                            }
                            if (
                              data.label.toLowerCase() === "completed" ||
                              data.label.toLowerCase() === "dispute" ||
                              data.label.toLowerCase() === "fixed"
                            ) {
                              formik.setFieldValue(
                                "actualClosedDate",
                                moment().utc().add(0, "days").format("YYYY-MM-DD HH:mm:ss")
                              );
                            } else {
                              formik.setFieldValue("actualClosedDate", null);
                            }
                          }}
                          isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                          }}
                          value={formik.values.resolutionId}
                          id="resolutionId"
                          name="resolutionId"
                          label="Resolution Status"
                          options={select.resolutionstatus.map((e) => {
                            return {
                              label: e.resolutionStatus1,
                              id: e.resolutionId,
                            };
                          })}
                        />
                      </>
                    )}
                  </Grid>
                  {viewissue === true ? (
                    <>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Actual Closed Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.actualClosedDate
                                  ? moment(data.actualClosedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Datetimepicker
                        readOnly
                        name="actualClosedDate"
                        //min={moment().format("YYYY-MM-DD")}
                        value={
                          formik.values.actualClosedDate
                            ? formik.values.actualClosedDate
                            : null
                        }
                        onChange={(e) => {
                          formik.setFieldValue("actualClosedDate", e);
                        }}
                        fullWidth
                        label="Actual Closed Date"
                      />
                    </Grid>
                  )}

                  {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    {viewissue === true ? (
                      <>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Developer Comments"
                          fullWidth
                          name="developerCommentsView"
                          value={formik.values.developerCommentsView}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "developerCommentsView",
                              e.target.value
                            );
                          }}
                          helperText={
                            formik.touched.developerCommentsView &&
                            formik.errors.developerCommentsView
                          }
                        />
                      </>
                    ) : (
                      <>
                        <Textbox
                          multiline
                          rows={3}
                          label="Developer Comments"
                          fullWidth
                          name="developerCommentsView"
                          value={formik.values.developerCommentsView}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "developerCommentsView",
                              e.target.value
                            );
                          }}
                          error={
                            formik.touched.developerCommentsView &&
                            Boolean(formik.errors.developerCommentsView)
                          }
                          helperText={
                            formik.touched.developerCommentsView &&
                            formik.errors.developerCommentsView
                          }
                        />
                      </>
                    )}
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Scrapper Development Required
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.isScrapperRequired === true
                            ? "YES"
                            : data.isScrapperRequired === false
                            ? "No"
                            : ""}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle2" gutterBottom>
                          Scrapper Development Required
                        </Typography>
                        <Grid
                          container
                          spacing={1.75}
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={"auto"}>
                            <Radiobutton
                              onChange={(_, data) => {
                                formik.setFieldValue(
                                  "isScrapperRequired",
                                  true
                                );
                              }}
                              itemvalue={true}
                              label="Yes"
                              value={formik.values.isScrapperRequired}
                            />
                          </Grid>
                          <Grid item xs={"auto"}>
                            <Radiobutton
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "isScrapperRequired",
                                  false
                                )
                              }
                              itemvalue={false}
                              label="No"
                              value={formik.values.isScrapperRequired}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    {viewissue === true ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Modified Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.updatedDate
                                  ? moment(data.updatedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Datetimepicker
                          readOnly
                          name="modifieddate"
                          value={data.updatedDate ? data.updatedDate : null}
                          onChange={(e) =>
                            formik.setFieldValue("updatedDate", e)
                          }
                          error={
                            formik.touched.updatedDate &&
                            Boolean(formik.errors.updatedDate)
                          }
                          helperText={
                            formik.touched.updatedDate &&
                            formik.errors.updatedDate
                          }
                          size="small"
                          fullWidth
                          label="Modified Date"
                        />
                      </>
                    )}
                  </Grid>
                  {/* hello */}
                  {viewissue === true ? (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Resolution Attachment(s)
                        </Typography>
                      </Grid>
                      {data.devAttachments ? (
                        data.devAttachments.split(",").length > 0 ? (
                          <>
                            {data.devAttachments.split(",").map((e) => (
                              <>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Tooltip placement="top" title={e} arrow>
                                    <Typography
                                      gutterBottom
                                      noWrap
                                      variant="body2"
                                    >
                                      <img
                                        src={pdfIcon}
                                        width="24"
                                        alt="pdf"
                                        style={{
                                          marginBottom: -5,
                                          marginRight: 4,
                                        }}
                                      />
                                      {e}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                              </>
                            ))}
                          </>
                        ) : (
                          <Grid item xs={12} md={6} lg={3}>
                            <Tooltip
                              placement="top"
                              title={data.attachments}
                              arrow
                            >
                              <Typography gutterBottom noWrap variant="body2">
                                <img
                                  src={pdfIcon}
                                  width="24"
                                  alt="pdf"
                                  style={{
                                    marginBottom: -5,
                                    marginRight: 4,
                                  }}
                                />
                                {data.devAttachments}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          style={{ marginTop: "12px", marginBottom: "5px" }}
                        >
                          Already Uploaded File(s)
                        </Typography>
                        <div className={classes.moreAttachments}>
                          <Grid
                            container
                            spacing={2}
                            alignItems="top"
                            justifyContent="flex-start"
                          >
                            {devdataattach ? (
                              devdataattach.split(",").length > 0 ? (
                                <>
                                  {devdataattach.split(",").map((e) => (
                                    <Grid item xs={12} md={4} lg={3}>
                                      <List className={classes.uploadFiles}>
                                        <ListItem
                                          className={classes.listItemLastStyle}
                                          //
                                          secondaryAction={
                                            <IconButton
                                              onClick={
                                                () => handleDeletedev(e)
                                                //setShowDeleteIssue(true)
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <img
                                                src={remove}
                                                width="20"
                                                alt="pdf"
                                              />
                                            </IconButton>
                                          }
                                        >
                                          <ListItemAvatar>
                                            <img
                                              src={pdfIcon}
                                              width="24"
                                              alt="pdf"
                                            />
                                          </ListItemAvatar>

                                          <ListItemText
                                            onClick={() => {
                                              devopenFileInNewTab(e);
                                            }}
                                            primary={`${truncateFileName(e)}`}
                                          />
                                        </ListItem>
                                      </List>
                                    </Grid>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <DropzoneDevEdit
                          margin="normal"
                          showSelectedDevFile={true}
                          isFileCleared={isFileCleareddev}
                          onFilesAdded={(e, fileUpload) =>
                            console.log(fileUpload)
                          }
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            {/* <Box className={classes.borderLine}> */}

            <Grid item xs={12}>
              <Box className={classes.borderLine}>
                <Grid
                  container
                  spacing={2}
                  alignItems="top"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12}>
                    <Grid item xs={"auto"} textAlign="right">
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Grid item xs="auto">
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            className={classes.titleLine}
                          >
                            <span className={classes.artLine}> </span>
                            Developer Comments
                            <span className={classes.artLine}></span>
                          </Typography>
                        </Grid>
                        <Grid item xs="auto"></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ overflowX: "auto", maxHeight: 300 }}>
                    {comments ? (
                      comments.map((e) => (
                        <Grid item xs={12}>
                          {" "}
                          <Box className={classes.commentBorderLine}>
                            {" "}
                            <Grid
                              container
                              spacing={0.5}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {" "}
                              <Grid item xs={6}>
                                {" "}
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                  className={classes.commentedBy}
                                >
                                  Commented by {e.createdBy}
                                </Typography>{" "}
                              </Grid>{" "}
                              <Grid item xs={6} textAlign="right">
                                {" "}
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                  className={classes.commentedBy}
                                >
                                  Commented On{" "}
                                  {moment(e.createdDate).format(
                                    "DD/MM/YYYY | h:mmA"
                                  )}
                                </Typography>{" "}
                              </Grid>{" "}
                              <Grid item xs={12}>
                                {" "}
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  {e.developerComments1}
                                </Typography>{" "}
                              </Grid>{" "}
                            </Grid>{" "}
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      <></>
                    )}
                  </Grid>

                  {!viewissue ? (
                    <>
                      <Grid item xs={12}>
                        {" "}
                        <Textbox
                          multiline
                          rows={3}
                          name="developerComments1"
                          length={1000}
                          onChange={(e) =>
                            formikComment.setFieldValue(
                              "developerComments1",
                              e.target.value
                            )
                          }
                          error={
                            formikComment.touched.developerComments1 &&
                            Boolean(formikComment.errors.developerComments1)
                          }
                          helperText={
                            formikComment.touched.developerComments1 &&
                            formikComment.errors.developerComments1
                          }
                          label="Developer Comments"
                          value={formikComment.values.developerComments1}
                          fullWidth
                        />{" "}
                      </Grid>{" "}
                      <Grid item xs={12} textAlign="right">
                        {" "}
                        <MaterialUIButton
                          variant="outlined"
                          message="SUBMIT"
                          disabled={
                            formikComment.values.developerComments1.length === 0
                          }
                          onClick={() => formikComment.handleSubmit()}
                          startIcon={<SaveOutlinedIcon />}
                        />{" "}
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
            </Grid>
            {/* </Box> */}
          </Grid>
        </Box>
      ) : (
        <>
          {role == "Merit QC" ? (
            <Box>
              <Grid
                container
                spacing={2.5}
                alignItems="center"
                justifyContent="flex-start"
              >
                {/* Popup Title Start Here */}
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs>
                      {viewissue === true ? (
                        <Typography variant="h6" gutterBottom>
                          Issue Details
                          <span className={classes.artSubText}>(View)</span>
                        </Typography>
                      ) : (
                        <Typography variant="h6" gutterBottom>
                          Issue Details
                          <span className={classes.artSubText}>(Edit)</span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={"auto"} textAlign="right">
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Grid item xs="auto">
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {viewissue === true ? (
                              <Grid item xs="auto">
                                {data.assignedTo ? (
                                  role === "Merit Developer" ? (
                                    <MaterialUIButton
                                      variant="outlined"
                                      message="EDIT"
                                      disabled={edit.length === 0}
                                      onClick={() => {
                                        setViewedit(true);
                                        SetViewIssue(false);
                                      }}
                                      startIcon={<EditIcon />}
                                    />
                                  ) : (
                                    <MaterialUIButton
                                      message="EDIT"
                                      onClick={() => {
                                        setViewedit(true);
                                        SetViewIssue(false);
                                      }}
                                      disabled
                                      startIcon={<EditIcon />}
                                    />
                                  )
                                ) : (
                                  <MaterialUIButton
                                    message="EDIT"
                                    onClick={() => {
                                      setViewedit(true);
                                      SetViewIssue(false);
                                    }}
                                    disabled={edit.length === 0}
                                    startIcon={<EditIcon />}
                                  />
                                )}
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs="auto">
                                  <MaterialUIButton
                                    variant="outlined"
                                    message="SAVE"
                                    disabled={props.save}
                                    onClick={() => formik.handleSubmit()}
                                    startIcon={<SaveOutlinedIcon />}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  {viewissue === true ? (
                                    <MaterialUIButton
                                      variant="outlined"
                                      message="CANCEL"
                                      onClick={() => {
                                        cancel();
                                      }}
                                      startIcon={<EventBusyOutlinedIcon />}
                                    />
                                  ) : (
                                    <MaterialUIButton
                                      variant="outlined"
                                      message="CANCEL"
                                      onClick={() => {
                                        JSON.stringify(
                                          formikComment.initialValues
                                        ) ===
                                          JSON.stringify(
                                            formikComment.values
                                          ) &&
                                        JSON.stringify(formik.initialValues) ===
                                          JSON.stringify(formik.values) &&
                                        (data.developerCommentsNavigation
                                          ? data.developerCommentsNavigation
                                              .length
                                          : 0) === comments.length &&
                                        select.files.length === 0
                                          ? cancel()
                                          : setShowalert(true);
                                      }}
                                      startIcon={<EventBusyOutlinedIcon />}
                                    />
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs="auto">
                          <Tooltip title="Close " placement="bottom" arrow>
                            <IconButton color="secondary" aria-label="Close">
                              <CloseIcon
                                onClick={() => {
                                  JSON.stringify(
                                    formikComment.initialValues
                                  ) === JSON.stringify(formikComment.values) &&
                                  JSON.stringify(formik.initialValues) ===
                                    JSON.stringify(formik.values) &&
                                  (data.developerCommentsNavigation
                                    ? data.developerCommentsNavigation.length
                                    : 0) === comments.length &&
                                  select.files.length === 0
                                    ? cancel()
                                    : setShowalert(true);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <AlertDialog
                          open={showalert}
                          onClose={() => setShowalert(false)}
                          // open={isDeleted}
                          title={"Confirmation"}
                          // maxWidth={"sm"}
                          description={`Do you want to discard the changes ?`}
                          action={
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="right"
                              spacing={2}
                              className={classes.alertDialogbtn}
                            >
                              <Grid item xs={"auto"}>
                                <MaterialUIButton
                                  variant="outlined"
                                  onClick={props.close}
                                  message="Yes"
                                  startIcon={<DoneIcon />}
                                />
                              </Grid>
                              <Grid item xs={"auto"}>
                                <MaterialUIButton
                                  variant="outlined"
                                  onClick={() => {
                                    setShowalert(false);
                                  }}
                                  message="No"
                                  startIcon={<CloseIcon />}
                                />
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Popup Title End Here */}

                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2.5}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          className={classes.titleLine}
                        >
                          <span className={classes.artLine}> </span>Issue
                          Details
                          <span className={classes.artLine}></span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Ticket Id
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>
                                {data.ticketId ? data.ticketId : ""}
                              </strong>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Textbox
                              size="small"
                              readOnly
                              label="Ticket Id"
                              name="ticket Id"
                              value={data.ticketId}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.ticketId &&
                                Boolean(formik.errors.ticketId)
                              }
                              helperText={
                                formik.touched.ticketId &&
                                formik.errors.ticketId
                              }
                              fullWidth
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Jira Ticket Id
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>
                                {data.jiraId ? data.jiraId : ""}
                              </strong>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Textbox
                              size="small"
                              readOnly
                              label="Jira Ticket Id"
                              name="jiraId"
                              value={data.jiraId}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.jiraId &&
                                Boolean(formik.errors.jiraId)
                              }
                              helperText={
                                formik.touched.jiraId &&
                                formik.errors.jiraId
                              }
                              fullWidth
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Date Raised
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.dateRaised
                                      ? moment(data.dateRaised).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datetimepicker
                              readOnly
                              name="dateRaised"
                              value={data.dateRaised}
                              onChange={(e) =>
                                formik.setFieldValue("dateRaised", e)
                              }
                              error={
                                formik.touched.dateRaised &&
                                Boolean(formik.errors.dateRaised)
                              }
                              helperText={
                                formik.touched.dateRaised &&
                                formik.errors.dateRaised
                              }
                              size="small"
                              fullWidth
                              label="Date Raised"
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              File Uploaded Date
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.dateofFile
                                      ? moment(data.dateofFile).format(
                                          "DD/MM/YYYY "
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datepicker
                              readOnly
                              name="dateofFile"
                              value={data.dateofFile}
                              onChange={(e) =>
                                formik.setFieldValue("dateofFile", e)
                              }
                              error={
                                formik.touched.dateofFile &&
                                Boolean(formik.errors.dateofFile)
                              }
                              helperText={
                                formik.touched.dateofFile &&
                                formik.errors.dateofFile
                              }
                              size="small"
                              fullWidth
                              label="File Uploaded Date"
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Country
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.country ? data.country.countryCode : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              required
                              value={formik.values.countryId}
                              onChange={(_, data) => {
                                formik.setFieldValue("countryId", data);
                                formik.setFieldValue("make", []);
                                formik.setFieldValue("modelId", []);

                                dispatch(makeDependency([data.id], id)).then(
                                  (res) => {
                                    if (res.data.statusCode === 200) {
                                      if (
                                        res.data.result === "No records found"
                                      ) {
                                        dispatch(getMake([]));
                                      } else {
                                        dispatch(getMake(res.data.result));
                                      }
                                    } else {
                                      dispatch(getMake([]));
                                    }
                                  }
                                );
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              error={
                                formik.touched.countryId &&
                                Boolean(formik.errors.countryId)
                              }
                              helperText={
                                formik.touched.countryId &&
                                formik.errors.countryId
                              }
                              labelId="Country"
                              id="Country"
                              name="countryId"
                              label="Country"
                              options={select.country.map((e) => {
                                return {
                                  label: e.countryCode + " - " + e.region,
                                  id: e.countryId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>
                      {Pname === "LEASE OPERATOR" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                Leasing Operator
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.provider
                                  ? data.provider.leasingProviderName
                                  : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Autocompletedropdown
                                required
                                // readOnly={true}
                                value={formik.values.provider}
                                onChange={(_, data, value) => {
                                  formik.setFieldValue("provider", data);
                                  if (value === "clear") {
                                    formik.setFieldValue("priorityId", null);
                                  } else {
                                    dispatch(
                                      priorityDropdownLease(data.priorityId, id)
                                    ).then((res) => {
                                      if (res.status === 200) {
                                        if (
                                          res.data.result ===
                                            "No records found" ||
                                          Object.keys(res.data).length === 0
                                        ) {
                                          formik.setFieldValue(
                                            "priorityId",
                                            null
                                          );
                                        } else {
                                          console.log(res.data);

                                          formik.setFieldValue("priorityId", {
                                            label: res.data.issuePriority1,
                                            id: res.data.priorityId,
                                          });
                                        }
                                      } else {
                                        formik.setFieldValue(
                                          "priorityId",
                                          null
                                        );
                                      }
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id;
                                }}
                                labelId="Provider"
                                id="Provider"
                                name="provider"
                                label="Leasing Provider"
                                getOptionLabel={(e) => e.leasingProviderName}
                                error={
                                  formik.touched.provider &&
                                  Boolean(formik.errors.provider)
                                }
                                helperText={
                                  formik.touched.provider &&
                                  formik.errors.provider
                                }
                                options={select.provider.map((e) => {
                                  return {
                                    priorityId: e.priorityId,
                                    label: e.leasingProviderName,
                                    id: e.providerId,
                                  };
                                })}
                              />
                            </>
                          )}
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {Pname === "TRANSACTIONAL ANALYSIS" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                website
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.website ? data.website.website1 : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Autocompletedropdown
                                required
                                value={formik.values.website}
                                onChange={(_, data) => {
                                  formik.setFieldValue("website", data);
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id;
                                }}
                                labelId="Website"
                                id="Website"
                                name="website"
                                label="Website"
                                getOptionLabel={(e) => e.website1}
                                error={
                                  formik.touched.website &&
                                  Boolean(formik.errors.website)
                                }
                                helperText={
                                  formik.touched.website &&
                                  formik.errors.website
                                }
                                options={select.website.map((e) => {
                                  return {
                                    label: e.website1,
                                    id: e.websiteId,
                                  };
                                })}
                              />
                            </>
                          )}
                        </Grid>
                      ) : (
                        <> </>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Make
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.fkMakeIssueTracker ? (
                                <>
                                  <Tooltip
                                    title={data.fkMakeIssueTracker.map(
                                      (e, i) => (
                                        <React.Fragment key={i}>
                                          {e.make.make1}
                                          {i !==
                                          data.fkMakeIssueTracker.length - 1
                                            ? ", "
                                            : ""}
                                        </React.Fragment>
                                      )
                                    )}
                                    placement="top"
                                    arrow
                                  >
                                    <Typography variant="body2" gutterBottom>
                                      {data.fkMakeIssueTracker ? (
                                        truncatemodel(
                                          data.fkMakeIssueTracker.map(
                                            (e) => e.make.make1
                                          )
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <MultipleAutocomplete
                              id="Make"
                              required
                              label="Make"
                              labelId="Make"
                              disabled={!formik.values.countryId ? true : false}
                              name="make"
                              value={formik.values.make}
                              filterOptions={(options, params) => {
                                const filtered = muifilter(options, params);
                                return [
                                  { makeId: 0, make1: "ALL" },
                                  ...filtered,
                                ];
                              }}
                              onChange={(_, e, value) => {
                                if (
                                  e.some((element) => element.make1 === "ALL")
                                ) {
                                  dispatch(
                                    modelDependency(
                                      select.make.map((f) => f.makeId),
                                      id
                                    )
                                  ).then((res) => {
                                    if (res.data.statusCode === 200) {
                                      if (
                                        res.data.result === "No records found"
                                      ) {
                                        dispatch(getModel([]));
                                      } else {
                                        dispatch(getModel(res.data.result));
                                      }
                                    } else {
                                      dispatch(getModel([]));
                                    }
                                  });
                                  dispatch(
                                    priorityDropdownNew(
                                      {
                                        countryId: formik.values.countryId.id,
                                        makeIds: select.make.map(
                                          (f) => f.makeId
                                        ),
                                      },
                                      id
                                    )
                                  ).then((res) => {
                                    if (res.status === 200) {
                                      if (
                                        res.data.result ===
                                          "No records found" ||
                                        Object.keys(res.data).length === 0
                                      ) {
                                        Pname !== "LEASE OPERATOR" &&
                                          formik.setFieldValue(
                                            "priorityId",
                                            null
                                          );
                                      } else {
                                        console.log(res.data);

                                        formik.setFieldValue("priorityId", {
                                          label: res.data.issuePriority1,
                                          id: res.data.priorityId,
                                        });
                                      }
                                    } else {
                                      Pname !== "LEASE OPERATOR" &&
                                        formik.setFieldValue(
                                          "priorityId",
                                          null
                                        );
                                    }
                                  });
                                } else {
                                  dispatch(
                                    modelDependency(
                                      e.map((f) => f.makeId),
                                      id
                                    )
                                  ).then((res) => {
                                    if (res.data.statusCode === 200) {
                                      if (
                                        res.data.result === "No records found"
                                      ) {
                                        dispatch(getModel([]));
                                      } else {
                                        dispatch(getModel(res.data.result));
                                      }
                                    } else {
                                      dispatch(getModel([]));
                                    }
                                  });
                                  dispatch(
                                    priorityDropdownNew(
                                      {
                                        countryId: formik.values.countryId.id,
                                        makeIds: e.map((f) => f.makeId),
                                      },
                                      id
                                    )
                                  ).then((res) => {
                                    if (res.status === 200) {
                                      if (
                                        res.data.result ===
                                          "No records found" ||
                                        Object.keys(res.data).length === 0
                                      ) {
                                        Pname !== "LEASE OPERATOR" &&
                                          formik.setFieldValue(
                                            "priorityId",
                                            null
                                          );
                                      } else {
                                        console.log(res);
                                        formik.setFieldValue("priorityId", {
                                          label: res.data.issuePriority1,
                                          id: res.data.priorityId,
                                        });
                                      }
                                    } else {
                                      Pname !== "LEASE OPERATOR" &&
                                        formik.setFieldValue(
                                          "priorityId",
                                          null
                                        );
                                    }
                                  });
                                }
                                formik.setFieldValue("modelId", []);
                                if (
                                  value === "selectOption" ||
                                  value === "removeOption"
                                ) {
                                  if (
                                    e.find((option) => option.make1 === "ALL")
                                  ) {
                                    let result = [];
                                    result = select.make.filter(
                                      (el) => el.make1 !== "ALL"
                                    );
                                    formik.setFieldValue("make", result);
                                  } else {
                                    handleToggleOptionm &&
                                      handleToggleOptionm(e);

                                    formik.setFieldValue("make", e);
                                  }
                                } else if (value === "clear") {
                                  formik.setFieldValue("make", []);
                                }
                                if (
                                  e.find((option) => option.make1 === "All")
                                ) {
                                  select.make.length ===
                                  formik.values.make?.length
                                    ? formik.setFieldValue("make", [])
                                    : formik.setFieldValue("make", select.make);
                                }
                              }}
                              renderTags={(value, getTagProps) =>
                                value.length === select.make.length ? (
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    label="ALL"
                                    //{...getTagProps({ index })}
                                  />
                                ) : (
                                  value.map((option, index) => (
                                    <Chip
                                      size="small"
                                      variant="outlined"
                                      label={option.make1}
                                      //{...getTagProps({ index })}
                                    />
                                  ))
                                )
                              }
                              //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
                              isOptionEqualToValue={(option, value) => {
                                return option.makeId === value.makeId;
                              }}
                              // getOptionSelected={(option, value) => option.value === value.value}
                              error={
                                formik.touched.make &&
                                Boolean(formik.errors.make)
                              }
                              helperText={
                                formik.touched.make && formik.errors.make
                              }
                              placeholder="Make"
                              limitTags={4}
                              options={select.make}
                              getOptionLabel={(e) => e.make1}
                              renderOption={optionRendererm}
                            />
                          </>
                        )}
                      </Grid>

                      {viewissue === true ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Model
                          </Typography>
                          {data.fkIssueTracker ? (
                            <>
                              <Tooltip
                                title={data.fkIssueTracker.map((e, i) => (
                                  <React.Fragment key={i}>
                                    {e.model.model1}
                                    {i !== data.fkIssueTracker.length - 1
                                      ? ", "
                                      : ""}
                                  </React.Fragment>
                                ))}
                                placement="top"
                                arrow
                              >
                                <Typography variant="body2" gutterBottom>
                                  {truncatemodel(
                                    data.fkIssueTracker.map(
                                      (e) => e.model.model1
                                    )
                                  )}
                                </Typography>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} lg={6}>
                          <MultipleAutocomplete
                            id="Model"
                            required={Pname === "OEMS"}
                            label="Model"
                            labelId="Model"
                            disabled={!formik.values.make ? true : false}
                            name="modelId"
                            value={formik.values.modelId}
                            filterOptions={(options, params) => {
                              const filtered = muifilter(options, params);
                              return [
                                { modelId: 0, model1: "Select All" },
                                ...filtered,
                              ];
                            }}
                            onChange={(_, e, value) => {
                              if (
                                value === "selectOption" ||
                                value === "removeOption"
                              ) {
                                if (
                                  e.find(
                                    (option) => option.model === "Select All"
                                  )
                                ) {
                                  let result = [];
                                  result = select.model.filter(
                                    (el) => el.model1 !== "Select All"
                                  );
                                  formik.setFieldValue("modelId", result);
                                } else {
                                  handleToggleOption && handleToggleOption(e);

                                  formik.setFieldValue("modelId", e);
                                }
                              } else if (value === "clear") {
                                formik.setFieldValue("modelId", []);
                              }
                              if (
                                e.find(
                                  (option) => option.model1 === "Select All"
                                )
                              ) {
                                select.model.length ===
                                formik.values.modelId.length
                                  ? formik.setFieldValue("modelId", [])
                                  : formik.setFieldValue(
                                      "modelId",
                                      select.model
                                    );
                              }
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  size="small"
                                  variant="outlined"
                                  label={option.model1}
                                  //{...getTagProps({ index })}
                                />
                              ))
                            }
                            //  isOptionEqualToValue={(option, value) =>  option.value === value.value }
                            isOptionEqualToValue={(option, value) => {
                              return option.modelId === value.modelId;
                            }}
                            // getOptionSelected={(option, value) => option.value === value.value}
                            error={
                              formik.touched.modelId &&
                              Boolean(formik.errors.modelId)
                            }
                            helperText={
                              formik.touched.modelId && formik.errors.modelId
                            }
                            placeholder="Model"
                            limitTags={4}
                            options={select.model}
                            getOptionLabel={(e) => e.model1}
                            renderOption={optionRenderer}
                          />
                        </Grid>
                      )}

                      {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Issue Category
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.category
                                ? data.category.issueCategory1
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              fullWidth
                              labelId="Issue Category"
                              id="Issue Category"
                              name="categoryId"
                              label="Issue Category"
                              value={formik.values.categoryId}
                              onChange={(_, data) => {
                                formik.setFieldValue("categoryId", data);
                                //formik.setFieldValue("issueType1", data);
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              error={
                                formik.touched.categoryId &&
                                Boolean(formik.errors.categoryId)
                              }
                              helperText={
                                formik.touched.categoryId &&
                                formik.errors.categoryId
                              }
                              options={select.category.map((e) => {
                                return {
                                  label: e.issueCategory1,
                                  id: e.categoryId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Issue Type
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.type ? data.type.issueType1 : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              required
                              fullWidth
                              name="typeId"
                              value={formik.values.typeId}
                              onChange={(_, data) => {
                                formik.setFieldValue("typeId", data);
                                formik.setFieldValue("issueType1", data);
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              error={
                                formik.touched.typeId &&
                                Boolean(formik.errors.typeId)
                              }
                              helperText={
                                formik.touched.typeId && formik.errors.typeId
                              }
                              labelId="Issue Type"
                              id="Issue Type"
                              label="Issue Type"
                              options={select.type.map((e) => {
                                return {
                                  label: e.issueType1,
                                  id: e.typeId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>
                      {/*  */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Business Priority
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.priority
                                ? data.priority.issuePriority1
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              fullWidth
                              required
                              readOnly
                              name="priorityId"
                              onChange={(_, e, value) => {
                                formik.setFieldValue("priorityId", e);
                                // changes as per SLA requested and FIxed ON 28-05-2024

                                // if (e.label.toLowerCase() === "critical")
                                //   formik.setFieldValue(
                                //     "plannedEndDate",
                                //     moment().add(1, "days")
                                //   );

                                // if (e.label.toLowerCase() === "high")
                                //   formik.setFieldValue(
                                //     "plannedEndDate",
                                //     moment().add(2, "days")
                                //   );
                                // if (e.label.toLowerCase() === "medium")
                                //   formik.setFieldValue(
                                //     "plannedEndDate",
                                //     moment().add(3, "days")
                                //   );
                                // if (e.label.toLowerCase() === "low")
                                //   formik.setFieldValue(
                                //     "plannedEndDate",
                                //     moment().add(4, "days")
                                //   );
                                // if (e === null && value === "clear") {
                                //   // formik.setFieldValue("priorityId",e);
                                //   formik.setFieldValue("plannedEndDate", null);
                                // }
                              }}
                              value={formik.values.priorityId}
                              error={
                                formik.touched.priorityId &&
                                Boolean(formik.errors.priorityId)
                              }
                              helperText={
                                formik.touched.priorityId &&
                                formik.errors.priorityId
                              }
                              labelId="Business Priority"
                              id="Business Priority"
                              label="Business Priority"
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              getOptionLabel={(e) => e.e.issuePriority1}
                              options={select.priority.map((e) => {
                                return {
                                  label: e.issuePriority1,
                                  id: e.priorityId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Issue Field
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.field ? data.field.issueField1 : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              value={formik.values.fieldId}
                              onChange={(_, data) => {
                                formik.setFieldValue("fieldId", data);
                                formik.setFieldValue("issueField1", data);
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              error={
                                formik.touched.fieldId &&
                                Boolean(formik.errors.fieldId)
                              }
                              helperText={
                                formik.touched.fieldId && formik.errors.fieldId
                              }
                              labelId="Issue Field"
                              id="Issue Field"
                              label="Issue Field"
                              fullWidth
                              name="fieldId"
                              options={select.field.map((e) => {
                                return {
                                  label: e.issueField1,
                                  id: e.fieldId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Issue/Request Type
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.requestType
                                ? data.requestType.requestTypeName
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <Autocompletedropdown
                            name="requestId"
                            required
                            value={formik.values.requestId}
                            onChange={(_, data) =>
                              formik.setFieldValue("requestId", data)
                            }
                            error={
                              formik.touched.requestId &&
                              Boolean(formik.errors.requestId)
                            }
                            helperText={
                              formik.touched.requestId &&
                              formik.errors.requestId
                            }
                            labelId="Request ID"
                            id="request id"
                            label="Issue/Request  Type"
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value.id;
                            }}
                            getOptionLabel={(e) => e.requestTypeName}
                            options={select.requestType.map((e) => {
                              return {
                                label: e.requestTypeName,
                                id: e.requestTypeId,
                              };
                            })}
                          />
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                Reported By
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.reportedByNavigation
                                  ? data.reportedByNavigation.reportedBy1
                                  : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Autocompletedropdown
                                required
                                value={formik.values.reportedBy}
                                onChange={(_, data) => {
                                  formik.setFieldValue("reportedBy", data);
                                  // formik.setFieldValue("reportedBy", data.id);
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id;
                                }}
                                error={
                                  formik.touched.reportedBy &&
                                  Boolean(formik.errors.reportedBy)
                                }
                                helperText={
                                  formik.touched.reportedBy &&
                                  formik.errors.reportedBy
                                }
                                //labelId="Reported By"
                                id="ReportedBy"
                                name="reportedBy"
                                label="Reported By"
                                options={select.reportedBy.map((e) => {
                                  return {
                                    label: e.reportedBy1,
                                    id: e.reportedById,
                                  };
                                })}
                              />
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                Added By
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.addedBy ? data.addedBy : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Textbox
                                readOnly
                                label="Added By"
                                name="addedBy"
                                value={data.addedBy}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.addedBy &&
                                  Boolean(formik.errors.addedBy)
                                }
                                helperText={
                                  formik.touched.addedBy &&
                                  formik.errors.addedBy
                                }
                                fullWidth
                              />
                            </>
                          )}
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Work Type
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.workType ? data.workType.workType1 : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              required
                              onChange={(_, data) => {
                                //formik.setFieldValue("workType", data);
                                formik.setFieldValue("workTypeId", data);
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option?.id === value?.id;
                              }}
                              value={formik.values.workTypeId}
                              id="workTypeId"
                              name="workTypeId"
                              label="Work Type"
                              error={
                                formik.touched.workTypeId &&
                                Boolean(formik.errors.workTypeId)
                              }
                              helperText={
                                formik.touched.workTypeId &&
                                formik.errors.workTypeId
                              }
                              options={select.worktype.map((e) => {
                                return {
                                  label: e.workType1,
                                  id: e.workTypeId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid> */}

                        {viewissue === true ? (
                          <>
                            <Grid item xs={12} md={6} lg={6}>
                              <Textbox
                                readOnly
                                multiline
                                rows={3}
                                label="Feedback"
                                fullWidth
                                name="feedback"
                                value={formik.values.feedback}
                                onChange={(data) => {
                                  formik.setFieldValue(
                                    "feedback",
                                    data.target.value
                                  );
                                }}
                                helperText={
                                  formik.touched.feedback &&
                                  formik.errors.feedback
                                }
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Textbox
                                required
                                multiline
                                rows={3}
                                label="Feedback"
                                fullWidth
                                name="feedback"
                                value={formik.values.feedback}
                                onChange={(data) => {
                                  formik.setFieldValue(
                                    "feedback",
                                    data.target.value
                                  );
                                }}
                                error={
                                  formik.touched.feedback &&
                                  Boolean(formik.errors.feedback)
                                }
                                helperText={
                                  formik.touched.feedback &&
                                  formik.errors.feedback
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={12} lg={6}>
                          {viewissue === true ? (
                            <>
                              <Textbox
                                readOnly
                                multiline
                                rows={3}
                                label="Merit Justification"
                                name="meritJustification"
                                value={formik.values.meritJustification}
                                onChange={(data) => {
                                  formik.setFieldValue(
                                    "meritJustification",
                                    data.target.value
                                  );
                                }}
                                helperText={
                                  formik.touched.meritJustification &&
                                  formik.errors.meritJustification
                                }
                                fullWidth
                              />
                            </>
                          ) : (
                            <>
                              <Textbox
                                multiline
                                rows={3}
                                label="Merit Justification"
                                name="meritJustification"
                                value={formik.values.meritJustification}
                                onChange={(data) => {
                                  formik.setFieldValue(
                                    "meritJustification",
                                    data.target.value
                                  );
                                }}
                                error={
                                  formik.touched.meritJustification &&
                                  Boolean(formik.errors.meritJustification)
                                }
                                helperText={
                                  formik.touched.meritJustification &&
                                  formik.errors.meritJustification
                                }
                                fullWidth
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                Rerun
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.rerun ? data.rerun.rerun1 : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Autocompletedropdown
                                required
                                onChange={(_, data) => {
                                  formik.setFieldValue("rerun", data);
                                  formik.setFieldValue("rerunId", data.id);
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id;
                                }}
                                error={
                                  formik.touched.rerun &&
                                  Boolean(formik.errors.rerun)
                                }
                                helperText={
                                  formik.touched.rerun && formik.errors.rerun
                                }
                                value={formik.values.rerun}
                                id="rerunId"
                                name="rerun"
                                label="Rerun"
                                options={select.rerun.map((e) => {
                                  return {
                                    label: e.rerun1,
                                    id: e.rerunId,
                                  };
                                })}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                          {viewissue === true ? (
                            <>
                              <Typography
                                variant="subtitle2"
                                display="block"
                                gutterBottom
                              >
                                Status
                              </Typography>
                              <Typography variant="body2" gutterBottom>
                                {data.status ? data.status.status1 : ""}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Autocompletedropdown
                                required
                                onChange={(_, data) => {
                                  // formik.setFieldValue("status", data);
                                  formik.setFieldValue("statusId", data);
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option.id === value.id;
                                }}
                                value={formik.values.statusId}
                                //inputValue={formik.values.workType.workType1}
                                error={
                                  formik.touched.statusId &&
                                  Boolean(formik.errors.statusId)
                                }
                                helperText={
                                  formik.touched.statusId &&
                                  formik.errors.statusId
                                }
                                id="statusId"
                                name="statusId"
                                label="Status"
                                options={select.status.map((e) => {
                                  return {
                                    label: e.status1,
                                    id: e.statusId,
                                  };
                                })}
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Attachment(s)
                          </Typography>
                        </Grid>
                        {viewissue === true ? (
                          <>
                            {data.attachments ? (
                              data.attachments.split(",").length > 0 ? (
                                <>
                                  {data.attachments.split(",").map((e) => (
                                    <>
                                      <Grid item xs={12} md={6} lg={3}>
                                        <Tooltip
                                          placement="top"
                                          title={e}
                                          arrow
                                        >
                                          <Typography
                                            gutterBottom
                                            noWrap
                                            variant="body2"
                                          >
                                            <img
                                              src={pdfIcon}
                                              width="24"
                                              alt="pdf"
                                              style={{
                                                marginBottom: -5,
                                                marginRight: 4,
                                              }}
                                            />
                                            {e}
                                          </Typography>
                                        </Tooltip>
                                      </Grid>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <Grid item xs={12} md={6} lg={3}>
                                  <Tooltip
                                    placement="top"
                                    title={data.attachments}
                                    arrow
                                  >
                                    <Typography
                                      gutterBottom
                                      noWrap
                                      variant="body2"
                                    >
                                      <img
                                        src={pdfIcon}
                                        width="24"
                                        alt="pdf"
                                        style={{
                                          marginBottom: -5,
                                          marginRight: 4,
                                        }}
                                      />
                                      {data.attachments}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                              )
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                style={{
                                  marginTop: "12px",
                                  marginBottom: "5px",
                                }}
                              >
                                Already Uploaded File(s)
                              </Typography>
                              <div className={classes.moreAttachments}>
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="top"
                                  justifyContent="flex-start"
                                >
                                  {dataattach ? (
                                    dataattach.split(",").length > 0 ? (
                                      <>
                                        {dataattach.split(",").map((e) => (
                                          <Grid item xs={12} md={4} lg={3}>
                                            <List
                                              className={classes.uploadFiles}
                                            >
                                              <ListItem
                                                className={
                                                  classes.listItemLastStyle
                                                }
                                                //
                                                secondaryAction={
                                                  <IconButton
                                                    onClick={
                                                      () => handleDelete(e)
                                                      //setShowDeleteIssue(true)
                                                    }
                                                    edge="end"
                                                    aria-label="delete"
                                                  >
                                                    <img
                                                      src={remove}
                                                      width="20"
                                                      alt="pdf"
                                                    />
                                                  </IconButton>
                                                }
                                              >
                                                <ListItemAvatar>
                                                  <img
                                                    src={pdfIcon}
                                                    width="24"
                                                    alt="pdf"
                                                  />
                                                </ListItemAvatar>

                                                <ListItemText
                                                  onClick={() => {
                                                    openFileInNewTab(e);
                                                  }}
                                                  primary={`${truncateFileName(
                                                    e
                                                  )}`}
                                                />
                                              </ListItem>
                                            </List>
                                          </Grid>
                                        ))}
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <DropzoneEdit
                                margin="normal"
                                showSelectedFile={true}
                                isFileCleared={isFileCleared}
                                onFilesAdded={(e, fileUpload) =>
                                  console.log(fileUpload)
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Grid item xs={"auto"} textAlign="right">
                          <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs="auto">
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className={classes.titleLine}
                              >
                                <span className={classes.artLine}> </span>
                                Resolution Details
                                <span className={classes.artLine}></span>
                              </Typography>
                            </Grid>
                            <Grid item xs="auto"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to First Response TAT
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data?.firstResponseTAT
                                  ? moment(data.firstResponseTAT).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to First Response
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.plannedEndDate
                                  ? moment(data.plannedEndDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Assigned To
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.assignedTo ? data.assignedTo : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Assigned Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.assignedDate
                                  ? moment(data.assignedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Category
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.category ? data.category.issueCategory1 : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Scrapper Complexity
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.complexity ? data.complexity : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Time to Resolution
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.resolutionDate
                                  ? moment(data.resolutionDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Resolution Status
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.resolution
                            ? data.resolution.resolutionStatus1
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Actual Closed Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.actualClosedDate
                                  ? moment(data.actualClosedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} md={12} lg={6}>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Developer Comments"
                          fullWidth
                          name="developerCommentsView"
                          value={formik.values.developerCommentsView}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "developerCommentsView",
                              e.target.value
                            );
                          }}
                          helperText={
                            formik.touched.developerCommentsView &&
                            formik.errors.developerCommentsView
                          }
                        />
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Scrapper Development Required
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.isScrapperRequired === true
                            ? "YES"
                            : data.isScrapperRequired === false
                            ? "No"
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Modified Date
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.updatedDate
                                  ? moment(data.updatedDate).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Resolution Attachment(s)
                        </Typography>
                      </Grid>
                      <>
                        {data.devAttachments ? (
                          data.devAttachments.split(",").length > 0 ? (
                            <>
                              {data.devAttachments.split(",").map((e) => (
                                <>
                                  <Grid item xs={12} md={6} lg={3}>
                                    <Tooltip placement="top" title={e} arrow>
                                      <Typography
                                        gutterBottom
                                        noWrap
                                        variant="body2"
                                      >
                                        <img
                                          src={pdfIcon}
                                          width="24"
                                          alt="pdf"
                                          style={{
                                            marginBottom: -5,
                                            marginRight: 4,
                                          }}
                                        />
                                        {e}
                                      </Typography>
                                    </Tooltip>
                                  </Grid>
                                </>
                              ))}
                            </>
                          ) : (
                            <Grid item xs={12} md={6} lg={3}>
                              <Tooltip
                                placement="top"
                                title={data.devAttachments}
                                arrow
                              >
                                <Typography gutterBottom noWrap variant="body2">
                                  <img
                                    src={pdfIcon}
                                    width="24"
                                    alt="pdf"
                                    style={{
                                      marginBottom: -5,
                                      marginRight: 4,
                                    }}
                                  />
                                  {data.devAttachments}
                                </Typography>
                              </Tooltip>
                            </Grid>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Grid item xs={"auto"} textAlign="right">
                          <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs="auto">
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className={classes.titleLine}
                              >
                                <span className={classes.artLine}> </span>
                                Developer Comments
                                <span className={classes.artLine}></span>
                              </Typography>
                            </Grid>
                            <Grid item xs="auto"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ overflowX: "auto", maxHeight: 300 }}
                      >
                        {comments ? (
                          comments.map((e) => (
                            <Grid item xs={12}>
                              {" "}
                              <Box className={classes.commentBorderLine}>
                                {" "}
                                <Grid
                                  container
                                  spacing={0.5}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  {" "}
                                  <Grid item xs={6}>
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                      className={classes.commentedBy}
                                    >
                                      Commented by {e.createdBy}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                  <Grid item xs={6} textAlign="right">
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                      className={classes.commentedBy}
                                    >
                                      Commented On{" "}
                                      {moment(e.createdDate).format(
                                        "DD/MM/YYYY | h:mmA"
                                      )}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                  <Grid item xs={12}>
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      {e.developerComments1}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                </Grid>{" "}
                              </Box>
                            </Grid>
                          ))
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {!viewissue ? (
                        <>
                          {/* <Grid item xs={12}>
              {" "}
              <Textbox
                multiline
                rows={3}
                name="developerComments1"
                length={1000}
                onChange={(e)=>formikComment.setFieldValue("developerComments1",e.target.value)}
                error={
                  formikComment.touched.developerComments1 &&
                   Boolean(formikComment.errors.developerComments1)
                }
                helperText={
                  formikComment.touched.developerComments1 &&
                  formikComment.errors.developerComments1
                }
                label="Developer Comments"
                
               value={formikComment.values.developerComments1}
                fullWidth
              />{" "}
            </Grid>{" "}
            <Grid item xs={12} textAlign="right">
              {" "}
              <MaterialUIButton
                variant="outlined"
                message="SUBMIT" 
                disabled={formikComment.values.developerComments1.length===0}
                 onClick={() => formikComment.handleSubmit()}
                startIcon={<SaveOutlinedIcon />}
              />{" "}
            </Grid> */}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid
                container
                spacing={2.5}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item xs>
                      {viewissue === true ? (
                        <Typography variant="h6" gutterBottom>
                          Issue Details
                          <span className={classes.artSubText}>(View)</span>
                        </Typography>
                      ) : (
                        <Typography variant="h6" gutterBottom>
                          Issue Details
                          <span className={classes.artSubText}>(Edit)</span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={"auto"} textAlign="right">
                      <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Grid item xs="auto">
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {viewissue === true ? (
                              <Grid item xs="auto">
                                <MaterialUIButton
                                  variant="outlined"
                                  message="EDIT"
                                  disabled={
                                    data.actualClosedDate ||
                                    edit.length === 0 /* ||
                                    userid !== data.assignedUserId */
                                  }
                                  onClick={() => {
                                    setViewedit(true);
                                    SetViewIssue(false);
                                  }}
                                  //disabled={edit.length === 0}
                                  startIcon={<EditIcon />}
                                />
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs="auto">
                                  <MaterialUIButton
                                    variant="outlined"
                                    message="SAVE"
                                    disabled={props.save}
                                    onClick={() => formik.handleSubmit()}
                                    startIcon={<SaveOutlinedIcon />}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  {viewissue === true ? (
                                    <MaterialUIButton
                                      variant="outlined"
                                      message="CANCEL"
                                      onClick={() => {
                                        cancel();
                                      }}
                                      startIcon={<EventBusyOutlinedIcon />}
                                    />
                                  ) : (
                                    <MaterialUIButton
                                      variant="outlined"
                                      message="CANCEL"
                                      onClick={() => {
                                        JSON.stringify(
                                          formikComment.initialValues
                                        ) ===
                                          JSON.stringify(
                                            formikComment.values
                                          ) &&
                                        JSON.stringify(formik.initialValues) ===
                                          JSON.stringify(formik.values) &&
                                        (data.developerCommentsNavigation
                                          ? data.developerCommentsNavigation
                                              .length
                                          : 0) === comments.length &&
                                        select.files.length === 0
                                          ? cancel()
                                          : setShowalert(true);
                                      }}
                                      startIcon={<EventBusyOutlinedIcon />}
                                    />
                                  )}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs="auto">
                          <Tooltip title="Close " placement="bottom" arrow>
                            <IconButton color="secondary" aria-label="Close">
                              <CloseIcon
                                onClick={() => {
                                  JSON.stringify(
                                    formikComment.initialValues
                                  ) === JSON.stringify(formikComment.values) &&
                                  (data.developerCommentsNavigation
                                    ? data.developerCommentsNavigation.length
                                    : 0) === comments.length &&
                                  JSON.stringify(formik.initialValues) ===
                                    JSON.stringify(formik.values)
                                    ? cancel()
                                    : setShowalert(true);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <AlertDialog
                          open={showalert}
                          onClose={() => setShowalert(false)}
                          // open={isDeleted}
                          title={"Confirmation"}
                          // maxWidth={"sm"}
                          description={`Do you want to discard the changes ?`}
                          action={
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="right"
                              spacing={2}
                              className={classes.alertDialogbtn}
                            >
                              <Grid item xs={"auto"}>
                                <MaterialUIButton
                                  variant="outlined"
                                  onClick={props.close}
                                  message="Yes"
                                  startIcon={<DoneIcon />}
                                />
                              </Grid>
                              <Grid item xs={"auto"}>
                                <MaterialUIButton
                                  variant="outlined"
                                  onClick={() => {
                                    setShowalert(false);
                                  }}
                                  message="No"
                                  startIcon={<CloseIcon />}
                                />
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Popup Title End Here */}

                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          className={classes.titleLine}
                        >
                          <span className={classes.artLine}> </span>Issue
                          Details
                          <span className={classes.artLine}></span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Ticket Id
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>{data.ticketId ? data.ticketId : ""}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Jira Ticket Id
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <strong>{data.jiraId ? data.jiraId : ""}</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Date Raised
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          <Grid item xs={"auto"} textAlign="right">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs="auto">
                                <img
                                  src={calendar}
                                  className={classes.dataIcon}
                                  width="16"
                                  alt="calendar"
                                />
                              </Grid>
                              <Grid item xs="auto">
                                {data.dateRaised
                                  ? moment(data.dateRaised).format(
                                      "DD/MM/YYYY HH:mm:ss"
                                    )
                                  : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Grid>
                      {role !== "Merit Developer" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            File Uploaded Date
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <Grid item xs={"auto"} textAlign="right">
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justifyContent="flex-start"
                              >
                                <Grid item xs="auto">
                                  <img
                                    src={calendar}
                                    className={classes.dataIcon}
                                    width="16"
                                    alt="calendar"
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  {data.dateofFile
                                    ? moment(data.dateofFile).format(
                                        "DD/MM/YYYY "
                                      )
                                    : ""}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Country
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.country ? data.country.countryCode : ""}
                        </Typography>
                      </Grid>
                      {Pname === "LEASE OPERATOR" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Leasing Provider
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {data.provider
                              ? data.provider.leasingProviderName
                              : ""}
                          </Typography>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {Pname === "TRANSACTIONAL ANALYSIS" ? (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Typography
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            Website
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {data.website ? data.website.website1 : ""}
                          </Typography>
                        </Grid>
                      ) : (
                        <> </>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Make
                        </Typography>
                        {data.fkMakeIssueTracker ? (
                          <>
                            <Tooltip
                              title={data.fkMakeIssueTracker.map((e, i) => (
                                <React.Fragment key={i}>
                                  {e.make.make1}
                                  {i !== data.fkMakeIssueTracker.length - 1
                                    ? ", "
                                    : ""}
                                </React.Fragment>
                              ))}
                              placement="top"
                              arrow
                            >
                              <Typography variant="body2" gutterBottom>
                                {data.fkMakeIssueTracker ? (
                                  truncatemodel(
                                    data.fkMakeIssueTracker.map(
                                      (e) => e.make.make1
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </Typography>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Model
                        </Typography>
                        {data.fkIssueTracker ? (
                          <>
                            <Tooltip
                              title={data.fkIssueTracker.map((e, i) => (
                                <React.Fragment key={i}>
                                  {e.model.model1}
                                  {i !== data.fkIssueTracker.length - 1
                                    ? ", "
                                    : ""}
                                </React.Fragment>
                              ))}
                              placement="top"
                              arrow
                            >
                              <Typography variant="body2" gutterBottom>
                                {data.fkIssueTracker ? (
                                  truncatemodel(
                                    data.fkIssueTracker.map(
                                      (e) => e.model.model1
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </Typography>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Category
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.category ? data.category.issueCategory1 : ""}
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.type ? data.type.issueType1 : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Business Priority
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.priority ? data.priority.issuePriority1 : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue Field
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.field ? data.field.issueField1 : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Issue/Request Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.requestType
                            ? data.requestType.requestTypeName
                            : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Reported By
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.reportedByNavigation
                            ? data.reportedByNavigation.reportedBy1
                            : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Added By
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.addedBy ? data.addedBy : ""}
                        </Typography>
                      </Grid>

                      {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Work Type
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.workType ? data.workType.workType1 : ""}
                        </Typography>
                      </Grid> */}

                      <Grid item xs={12} md={6} lg={6}>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Feedback"
                          fullWidth
                          name="feedback"
                          value={formik.values.feedback}
                          onChange={(data) => {
                            formik.setFieldValue("feedback", data.target.value);
                          }}
                          helperText={
                            formik.touched.feedback && formik.errors.feedback
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <Textbox
                          readOnly
                          multiline
                          rows={3}
                          label="Merit Justification"
                          name="meritJustification"
                          value={formik.values.meritJustification}
                          onChange={(data) => {
                            formik.setFieldValue(
                              "meritJustification",
                              data.target.value
                            );
                          }}
                          helperText={
                            formik.touched.meritJustification &&
                            formik.errors.meritJustification
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Rerun
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.rerun ? data.rerun.rerun1 : ""}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Status
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {data.status ? data.status.status1 : ""}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          gutterBottom
                        >
                          Attachment(s)
                        </Typography>
                      </Grid>

                      {data.attachments ? (
                        data.attachments.split(",").length > 0 ? (
                          <>
                            {data.attachments.split(",").map((e) => (
                              <>
                                <Grid item xs={12} md={6} lg={3}>
                                  <Tooltip placement="top" title={e} arrow>
                                    <Typography
                                      gutterBottom
                                      noWrap
                                      variant="body2"
                                    >
                                      <img
                                        src={pdfIcon}
                                        width="24"
                                        alt="pdf"
                                        style={{
                                          marginBottom: -5,
                                          marginRight: 4,
                                        }}
                                      />
                                      {e}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                              </>
                            ))}
                          </>
                        ) : (
                          <Grid item xs={12} md={6} lg={3}>
                            <Tooltip
                              placement="top"
                              title={data.attachments}
                              arrow
                            >
                              <Typography gutterBottom noWrap variant="body2">
                                <img
                                  src={pdfIcon}
                                  width="24"
                                  alt="pdf"
                                  style={{
                                    marginBottom: -5,
                                    marginRight: 4,
                                  }}
                                />
                                {data.attachments}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        )
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                {/* dev edit */}
                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Grid item xs={"auto"} textAlign="right">
                          <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs="auto">
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className={classes.titleLine}
                              >
                                <span className={classes.artLine}> </span>
                                Resolution Details
                                <span className={classes.artLine}></span>
                              </Typography>
                            </Grid>
                            <Grid item xs="auto"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Time to First Response TAT
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data?.firstResponseTAT
                                      ? moment(data.firstResponseTAT).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datetimepicker
                              onKeyDown={onKeyDown}
                              name="TAT"
                              // changes as per SLA requested and FIxed ON 28-05-2024
                              // min={moment().format("YYYY-MM-DD")}
                              value={
                                data?.firstResponseTAT
                                  ? data?.firstResponseTAT
                                  : null
                              }
                              // onChange={(e) => {
                              //   formik.setFieldValue("plannedEndDate", e);
                              // }}
                              // error={
                              //   formik.touched.plannedEndDate &&
                              //   Boolean(formik.errors.plannedEndDate)
                              // }
                              // helperText={
                              //   formik.touched.plannedEndDate &&
                              //   formik.errors.plannedEndDate
                              // }
                              fullWidth
                              // changes as per SLA requested and FIxed ON 28-05-2024
                              readOnly
                              label="Time to First Response TAT"
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Time to First Response
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.plannedEndDate
                                      ? moment(data.plannedEndDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datetimepicker
                              onKeyDown={onKeyDown}
                              name="plannedEndDate"
                              // changes as per SLA requested and FIxed ON 28-05-2024
                              // min={moment().format("YYYY-MM-DD")}
                              value={formik.values.plannedEndDate}
                              onChange={(e) => {
                                formik.setFieldValue("plannedEndDate", e);
                              }}
                              error={
                                formik.touched.plannedEndDate &&
                                Boolean(formik.errors.plannedEndDate)
                              }
                              helperText={
                                formik.touched.plannedEndDate &&
                                formik.errors.plannedEndDate
                              }
                              fullWidth
                              // changes as per SLA requested and FIxed ON 28-05-2024
                              readOnly
                              label="Time to First Response"
                            />
                          </>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Assigned To
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.assignedTo ? data.assignedTo : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              required
                              //readOnly
                              onChange={(_, data1, value) => {
                                formik.setFieldValue("assignedTo", data1);
                                if (data1 == null && value === "clear") {
                                  // changes as per SLA requested and FIxed ON 28-05-2024
                                  formik.setFieldValue("assignedDate", null);
                                  // formik.setFieldValue("priorityId",e);
                                  if (data.plannedEndDate === null) {
                                    formik.setFieldValue(
                                      "plannedEndDate",
                                      null
                                    );
                                  }

                                  const status = select.resolutionstatus.filter(
                                    (e) =>
                                      e.resolutionStatus1.toLowerCase() ===
                                      "yet to start"
                                  );
                                  console.log(select.resolutionstatus);
                                  status.map((e) => {
                                    formik.setFieldValue("resolutionId", {
                                      label: e.resolutionStatus1,
                                      id: e.resolutionId,
                                    });
                                  });
                                  // changes as per SLA requested and FIxed ON 28-05-2024
                                } else {
                                  if (formik.values.categoryId) {
                                    const status =
                                      select.resolutionstatus.filter(
                                        (e) =>
                                          e.resolutionStatus1.toLowerCase() ===
                                          "rca"
                                      );
                                    console.log(select.resolutionstatus);
                                    status.map((e) => {
                                      formik.setFieldValue("resolutionId", {
                                        label: e.resolutionStatus1,
                                        id: e.resolutionId,
                                      });
                                    });
                                  } else {
                                    const status =
                                      select.resolutionstatus.filter(
                                        (e) =>
                                          e.resolutionStatus1.toLowerCase() ===
                                          "yet to start"
                                      );
                                    console.log(select.resolutionstatus);
                                    status.map((e) => {
                                      formik.setFieldValue("resolutionId", {
                                        label: e.resolutionStatus1,
                                        id: e.resolutionId,
                                      });
                                    });
                                  }
                                  // changes as per SLA requested and FIxed ON 22-07-2024
                                  // if (
                                  //   data.plannedEndDate === null ||
                                  //   formik.values.plannedEndDate === null
                                  // ) {
                                  //   formik.setFieldValue(
                                  //     "plannedEndDate",
                                  //     moment().add(0, "days")
                                  //   );
                                  // }
                                  if (data.assignedTo !== data1.label) {
                                    formik.setFieldValue(
                                      "assignedDate",
                                      moment()
                                        .utc()

                                        .format("YYYY-MM-DD HH:mm:ss")
                                    );
                                  } else {
                                    formik.setFieldValue(
                                      "assignedDate",
                                      data.assignedDate
                                    );
                                  }
                                }
                                //formik.setFieldValue("assignedToView", data.id);
                              }}
                              /* isOptionEqualToValue={(option, value) => {
                                              return option.id === value.id;
                                            }} */
                              value={formik.values.assignedTo}
                              id="userId"
                              name="assignedTo"
                              label="Assigned To"
                              error={
                                formik.touched.assignedTo &&
                                Boolean(formik.errors.assignedTo)
                              }
                              options={
                                role.toLowerCase().includes("merit")
                                  ? select.meritusers.map((e) => ({
                                      label: e.name,
                                      id: e.userId,
                                    }))
                                  : select.users.map((e) => ({
                                      label: e.name,
                                      id: e.userId,
                                    }))
                              }
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Assigned Date
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.assignedDate
                                      ? moment(data.assignedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datetimepicker
                              name="assignedDate"
                              value={
                                formik.values.assignedDate
                                  ? formik.values.assignedDate
                                  : null
                              }
                              readOnly
                              size="small"
                              fullWidth
                              label="Assigned Date"
                            />
                            {/* <Datepicker
                          name="assignedDateView"
                          value={
                            formik.values.assignedDateView
                              ? formik.values.assignedDateView
                              : null
                          }
                          readOnly
                          size="small"
                          fullWidth
                          label="Assigned Date"
                        /> */}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Issue Category
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.category
                                ? data.category.issueCategory1
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              fullWidth
                              labelId="Issue Category"
                              id="Issue Category"
                              name="categoryId"
                              label="Issue Category"
                              value={formik.values.categoryId}
                              onChange={(_, data, value) => {
                                dispatch(fetchResolutionstatus([]));
                                formik.setFieldValue("categoryId", data);

                                dispatch(Status_on(data.label, id)).then(
                                  (res) => {
                                    if (res.data.statusCode === 200) {
                                      if (
                                        res.data.result === "No records found"
                                      ) {
                                        dispatch(fetchResolutionstatus([]));
                                      } else
                                        dispatch(
                                          fetchResolutionstatus(res.data.result)
                                        );
                                    } else {
                                      dispatch(fetchResolutionstatus([]));
                                    }
                                  }
                                );
                                const status = select.resolutionstatus.filter(
                                  (e) =>
                                    e.resolutionStatus1.toLowerCase() === "rca"
                                );
                                status.map((e) => {
                                  formik.setFieldValue("resolutionId", {
                                    label: e.resolutionStatus1,
                                    id: e.resolutionId,
                                  });
                                });

                                if (value === "clear") {
                                  dispatch(Status_on(data.label, id)).then(
                                    (res) => {
                                      if (res.data.statusCode === 200) {
                                        if (
                                          res.data.result === "No records found"
                                        ) {
                                          dispatch(fetchResolutionstatus([]));
                                        } else
                                          dispatch(
                                            fetchResolutionstatus(
                                              res.data.result
                                            )
                                          );
                                      } else {
                                        dispatch(fetchResolutionstatus([]));
                                      }
                                    }
                                  );
                                }
                                //formik.setFieldValue("issueType1", data);
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              error={
                                formik.touched.categoryId &&
                                Boolean(formik.errors.categoryId)
                              }
                              helperText={
                                formik.touched.categoryId &&
                                formik.errors.categoryId
                              }
                              options={select.category.map((e) => {
                                return {
                                  label: e.issueCategory1,
                                  id: e.categoryId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Scrapper Complexity
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.complexity ? data.complexity : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              required
                              readOnly={
                                !formik.values.assignedTo ? true : false
                              }
                              onChange={(_, e, value) => {
                                formik.setFieldValue("complexity", e);
                                if (e == null && value == "clear") {
                                  console.log("hey2", e, value);
                                  formik.setFieldValue("resolutionId", null);

                                  formik.setFieldValue("plannedEndDate", null);

                                  // formik.setFieldValue("priorityId",e);
                                  formik.setFieldValue(
                                    "resolutionDateView",
                                    null
                                  );
                                } else {
                                  if (
                                    data.plannedEndDate === null ||
                                    formik.values.plannedEndDate === null
                                  ) {
                                    formik.setFieldValue(
                                      "plannedEndDate",
                                      moment()
                                        .utc()

                                        .add(0, "days")
                                        .format("YYYY-MM-DD HH:mm:ss")
                                    );
                                  }
                                  formik.setFieldValue(
                                    "resolutionDateView",
                                    addDecimalBusinessDays(
                                      // moment()
                                      //   .utc()
                                      //   .format("YYYY-MM-DD HH:mm:ss"),
                                      formik.values.assignedDate,
                                      getTotalDays(
                                        e.label,
                                        formik.values.priorityId.label
                                      ),
                                      holiday.map((e) =>
                                        moment(e.date).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      )
                                    )
                                  );
                                  JSON.parse(
                                    localStorage.getItem("status")
                                  ).map((e) => {
                                    formik.setFieldValue("resolutionId", {
                                      label: e.resolutionStatus1,
                                      id: e.resolutionId,
                                    });
                                  });
                                  // if (e.label.toLowerCase() === "extreme") {

                                  // }
                                  // if (e.label.toLowerCase() === "high") {

                                  //   JSON.parse(localStorage.getItem("status")).map(
                                  //     (e) => {
                                  //       formik.setFieldValue("resolutionId", {
                                  //         label: e.resolutionStatus1,
                                  //         id: e.resolutionId,
                                  //       });
                                  //     }
                                  //   );
                                  // }
                                  // if (e.label.toLowerCase() === "medium") {

                                  //   JSON.parse(localStorage.getItem("status")).map(
                                  //     (e) => {
                                  //       formik.setFieldValue("resolutionId", {
                                  //         label: e.resolutionStatus1,
                                  //         id: e.resolutionId,
                                  //       });
                                  //     }
                                  //   );
                                  // }
                                  // if (e.label.toLowerCase() === "low") {

                                  //   JSON.parse(localStorage.getItem("status")).map(
                                  //     (e) => {
                                  //       formik.setFieldValue("resolutionId", {
                                  //         label: e.resolutionStatus1,
                                  //         id: e.resolutionId,
                                  //       });
                                  //     }
                                  //   );
                                  // }
                                }
                              }}
                              value={formik.values.complexity}
                              //disableClearable
                              id="complexity"
                              name="complexity"
                              label="Scrapper Complexity"
                              error={
                                formik.touched.complexity &&
                                Boolean(formik.errors.complexity)
                              }
                              helperText={
                                formik.touched.complexity &&
                                formik.errors.complexity
                              }
                              options={select.developercomplexity.map((e) => {
                                return {
                                  label: e,
                                  id: e,
                                };
                              })}
                              getOptionDisabled={(option) => formik.values.complexity?.id === option.id}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                          </>
                        )}
                      </Grid>

                      {viewissue === true ? (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Time to Resolution
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.resolutionDate
                                      ? moment(data.resolutionDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Datetimepicker
                            readOnly
                            name="resolutionDateView"
                            // min={moment().format("YYYY-MM-DD")}
                            value={
                              formik.values.resolutionDateView
                                ? formik.values.resolutionDateView
                                : null
                            }
                            onChange={(e) => {
                              formik.setFieldValue("resolutionDateView", e);
                            }}
                            fullWidth
                            label="Time to Resolution"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Resolution Status
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.resolution
                                ? data.resolution.resolutionStatus1
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Autocompletedropdown
                              readOnly={
                                !formik.values.complexity ? true : false
                              }
                              onChange={(_, data, value) => {
                                //formik.setFieldValue("resolution", data);

                                formik.setFieldValue("resolutionId", data);
                                if (data == null && value == "clear") {
                                  // formik.setFieldValue("priorityId",e);
                                  formik.setFieldValue(
                                    "actualClosedDate",
                                    null
                                  );
                                }
                                if (
                                  data.label.toLowerCase() === "completed" ||
                                  data.label.toLowerCase() === "dispute" ||
                                  data.label.toLowerCase() === "fixed"
                                ) {
                                  formik.setFieldValue(
                                    "actualClosedDate",
                                    moment().utc().add(0, "days").format("YYYY-MM-DD HH:mm:ss")
                                  );
                                } else {
                                  formik.setFieldValue(
                                    "actualClosedDate",
                                    null
                                  );
                                }
                              }}
                              isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                              }}
                              value={formik.values.resolutionId}
                              id="resolutionId"
                              name="resolutionId"
                              label="Resolution Status"
                              options={select.resolutionstatus.map((e) => {
                                return {
                                  label: e.resolutionStatus1,
                                  id: e.resolutionId,
                                };
                              })}
                            />
                          </>
                        )}
                      </Grid>
                      {viewissue === true ? (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Actual Closed Date
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.actualClosedDate
                                      ? moment(data.actualClosedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Datetimepicker
                            readOnly
                            name="actualClosedDate"
                            //min={moment().format("YYYY-MM-DD")}
                            value={
                              formik.values.actualClosedDate
                                ? formik.values.actualClosedDate
                                : null
                            }
                            onChange={(e) => {
                              formik.setFieldValue("actualClosedDate", e);
                            }}
                            fullWidth
                            label="Actual Closed Date"
                          />
                        </Grid>
                      )}

                      {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                        {viewissue === true ? (
                          <>
                            <Textbox
                              readOnly
                              multiline
                              rows={3}
                              label="Developer Comments"
                              fullWidth
                              name="developerCommentsView"
                              value={formik.values.developerCommentsView}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "developerCommentsView",
                                  e.target.value
                                );
                              }}
                              helperText={
                                formik.touched.developerCommentsView &&
                                formik.errors.developerCommentsView
                              }
                            />
                          </>
                        ) : (
                          <>
                            <Textbox
                              multiline
                              rows={3}
                              label="Developer Comments"
                              fullWidth
                              name="developerCommentsView"
                              value={formik.values.developerCommentsView}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "developerCommentsView",
                                  e.target.value
                                );
                              }}
                              error={
                                formik.touched.developerCommentsView &&
                                Boolean(formik.errors.developerCommentsView)
                              }
                              helperText={
                                formik.touched.developerCommentsView &&
                                formik.errors.developerCommentsView
                              }
                            />
                          </>
                        )}
                      </Grid> */}
                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Scrapper Development Required
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {data.isScrapperRequired === true
                                ? "YES"
                                : data.isScrapperRequired === false
                                ? "No"
                                : ""}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant="subtitle2" gutterBottom>
                              Scrapper Development Required
                            </Typography>
                            <Grid
                              container
                              spacing={1.75}
                              justifyContent="flex-start"
                              alignItems="flex-start"
                            >
                              <Grid item xs={"auto"}>
                                <Radiobutton
                                  onChange={(_, data) => {
                                    formik.setFieldValue(
                                      "isScrapperRequired",
                                      true
                                    );
                                  }}
                                  itemvalue={true}
                                  label="Yes"
                                  value={formik.values.isScrapperRequired}
                                />
                              </Grid>
                              <Grid item xs={"auto"}>
                                <Radiobutton
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "isScrapperRequired",
                                      false
                                    )
                                  }
                                  itemvalue={false}
                                  label="No"
                                  value={formik.values.isScrapperRequired}
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        {viewissue === true ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Modified Date
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <Grid item xs={"auto"} textAlign="right">
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Grid item xs="auto">
                                    <img
                                      src={calendar}
                                      className={classes.dataIcon}
                                      width="16"
                                      alt="calendar"
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    {data.updatedDate
                                      ? moment(data.updatedDate).format(
                                          "DD/MM/YYYY HH:mm:ss"
                                        )
                                      : ""}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Datetimepicker
                              readOnly
                              name="modifieddate"
                              value={data.updatedDate ? data.updatedDate : null}
                              onChange={(e) =>
                                formik.setFieldValue("updatedDate", e)
                              }
                              error={
                                formik.touched.updatedDate &&
                                Boolean(formik.errors.updatedDate)
                              }
                              helperText={
                                formik.touched.updatedDate &&
                                formik.errors.updatedDate
                              }
                              size="small"
                              fullWidth
                              label="Modified Date"
                            />
                          </>
                        )}
                      </Grid>
                      {viewissue === true ? (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              display="block"
                              gutterBottom
                            >
                              Resolution Attachment(s)
                            </Typography>
                          </Grid>
                          {data.devAttachments ? (
                            data.devAttachments.split(",").length > 0 ? (
                              <>
                                {data.devAttachments.split(",").map((e) => (
                                  <>
                                    <Grid item xs={12} md={6} lg={3}>
                                      <Tooltip placement="top" title={e} arrow>
                                        <Typography
                                          gutterBottom
                                          noWrap
                                          variant="body2"
                                        >
                                          <img
                                            src={pdfIcon}
                                            width="24"
                                            alt="pdf"
                                            style={{
                                              marginBottom: -5,
                                              marginRight: 4,
                                            }}
                                          />
                                          {e}
                                        </Typography>
                                      </Tooltip>
                                    </Grid>
                                  </>
                                ))}
                              </>
                            ) : (
                              <Grid item xs={12} md={6} lg={3}>
                                <Tooltip
                                  placement="top"
                                  title={data.devAttachments}
                                  arrow
                                >
                                  <Typography
                                    gutterBottom
                                    noWrap
                                    variant="body2"
                                  >
                                    <img
                                      src={pdfIcon}
                                      width="24"
                                      alt="pdf"
                                      style={{
                                        marginBottom: -5,
                                        marginRight: 4,
                                      }}
                                    />
                                    {data.devAttachments}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                            )
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              style={{
                                marginTop: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              Already Uploaded File(s)
                            </Typography>
                            <div className={classes.moreAttachments}>
                              <Grid
                                container
                                spacing={2}
                                alignItems="top"
                                justifyContent="flex-start"
                              >
                                {devdataattach ? (
                                  devdataattach.split(",").length > 0 ? (
                                    <>
                                      {devdataattach.split(",").map((e) => (
                                        <Grid item xs={12} md={4} lg={3}>
                                          <List className={classes.uploadFiles}>
                                            <ListItem
                                              className={
                                                classes.listItemLastStyle
                                              }
                                              //
                                              secondaryAction={
                                                <IconButton
                                                  onClick={
                                                    () => handleDeletedev(e)
                                                    //setShowDeleteIssue(true)
                                                  }
                                                  edge="end"
                                                  aria-label="delete"
                                                >
                                                  <img
                                                    src={remove}
                                                    width="20"
                                                    alt="pdf"
                                                  />
                                                </IconButton>
                                              }
                                            >
                                              <ListItemAvatar>
                                                <img
                                                  src={pdfIcon}
                                                  width="24"
                                                  alt="pdf"
                                                />
                                              </ListItemAvatar>

                                              <ListItemText
                                                onClick={() => {
                                                  devopenFileInNewTab(e);
                                                }}
                                                primary={`${truncateFileName(
                                                  e
                                                )}`}
                                              />
                                            </ListItem>
                                          </List>
                                        </Grid>
                                      ))}
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <DropzoneDevEdit
                              margin="normal"
                              showSelectedDevFile={true}
                              isFileCleared={isFileCleareddev}
                              onFilesAdded={(e, fileUpload) =>
                                console.log(fileUpload)
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.borderLine}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="top"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <Grid item xs={"auto"} textAlign="right">
                          <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Grid item xs="auto">
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className={classes.titleLine}
                              >
                                <span className={classes.artLine}> </span>
                                Developer Comments
                                <span className={classes.artLine}></span>
                              </Typography>
                            </Grid>
                            <Grid item xs="auto"></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ overflowX: "auto", maxHeight: 300 }}
                      >
                        {comments ? (
                          comments.map((e) => (
                            <Grid item xs={12}>
                              {" "}
                              <Box className={classes.commentBorderLine}>
                                {" "}
                                <Grid
                                  container
                                  spacing={0.5}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  {" "}
                                  <Grid item xs={6}>
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                      className={classes.commentedBy}
                                    >
                                      Commented by {e.createdBy}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                  <Grid item xs={6} textAlign="right">
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                      className={classes.commentedBy}
                                    >
                                      Commented On{" "}
                                      {moment(e.createdDate).format(
                                        "DD/MM/YYYY | h:mmA"
                                      )}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                  <Grid item xs={12}>
                                    {" "}
                                    <Typography
                                      variant="caption"
                                      display="block"
                                      gutterBottom
                                    >
                                      {e.developerComments1}
                                    </Typography>{" "}
                                  </Grid>{" "}
                                </Grid>{" "}
                              </Box>
                            </Grid>
                          ))
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {!viewissue ? (
                        <>
                          <Grid item xs={12}>
                            {" "}
                            <Textbox
                              multiline
                              rows={3}
                              name="developerComments1"
                              length={1000}
                              onChange={(e) =>
                                formikComment.setFieldValue(
                                  "developerComments1",
                                  e.target.value
                                )
                              }
                              error={
                                formikComment.touched.developerComments1 &&
                                Boolean(formikComment.errors.developerComments1)
                              }
                              helperText={
                                formikComment.touched.developerComments1 &&
                                formikComment.errors.developerComments1
                              }
                              label="Developer Comments"
                              value={formikComment.values.developerComments1}
                              fullWidth
                            />{" "}
                          </Grid>{" "}
                          <Grid item xs={12} textAlign="right">
                            {" "}
                            <MaterialUIButton
                              variant="outlined"
                              message="SUBMIT"
                              disabled={
                                formikComment.values.developerComments1
                                  .length === 0
                              }
                              onClick={() => formikComment.handleSubmit()}
                              startIcon={<SaveOutlinedIcon />}
                            />{" "}
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default ViewIssue;
